<template>
  <div>
    <h4>Listado de roles</h4>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Nombre</th>
          <th>Acciones</th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td>{{ elemento.rol }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del rol</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Nombre rol</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.rol"
            class="form-control"
            :class="estatus($v.actual.rol)"
          />
          <div class="errores" v-if="!$v.actual.rol.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Permisos</label>
          <div style="height: 150px; overflow-y: auto;">
            <tree-item
              class="item"
              :item="organigramas"
            ></tree-item>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import TreeItem from "@/components/TreeItem3.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  components: { TreeItem },
  data() {
    return {
      titulo: "Agregar rol",
      listado: [],
      listo: [],
      buscando: false,
      organigrama: {},
      padre: {},
      organigramas: {},
      actual: {area:null,responsable:null,cargo:null,email:null,telefono:null, telefonod:null },
    };
  },
  validations() {
    return {
      actual: {
        rol: {
          required,
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo = "Agregar rol";
      this.actual = {};
      this.$refs.detalle.show();
    },
    editar (elemento) {
      for(var l of this.listo) {
        l.seleccionado = elemento.permisos.includes(l.id);
      }
      this.titulo = "Editar rol",
      this.actual = Object.assign({}, elemento);
      this.$refs.detalle.show();
    },
    eliminar (elemento) {
      if ( confirm("Confirma eliminar el rol: "+elemento.rol)) {
        api.eliminarRol(elemento.id)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizarRoles();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {this.$parent.aviso("No se pudo eliminar "+error);});
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        let ps = [];
        for (var l of this.listo) {
          if (l.seleccionado && l.id) {
            ps.push(l.id);
          }
        }
        this.actual.permisos = ps;
        api
          .guardarRol(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarRoles();
            } else {
              this.$parent.error("Error al guardar la información "+r.mensaje);
            }
          })
          .catch((error) => {
              this.$parent.error("Error al guardar la información "+error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    actualizarRoles() {
      api.listarRolesDetalle().then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        } else {
          this.listado = [];
        }
      });
    },
    actualizaOrganigrama() {
      api.listarFunciones("").then((response) => {
        if (response.data) {
          this.organigramas = response.data;
          this._agregaFunciones(this.organigramas);
        }
      });
    },
    _agregaFunciones(org) {
      org.seleccionado = false;
      this.listo.push(org);
      for(var o of org.children) {
        this._agregaFunciones(o);
      }
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizarRoles();
    this.actualizaOrganigrama();
  },
};

</script>

