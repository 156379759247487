<template>
  <div>
    <h4>Información</h4>
    <ul>
      <tree-carpeta
        class="item"
        :item="carpetas"
        @add-archivo="addArchivo"
        @edit-archivo="editArchivo"
        @del-archivo="delArchivo"
        @open-archivo="openArchivo"
        @add-nodo="addNodo"
        @edit-files="editFiles"
        @edit-nodo="editNodo"
        @del-nodo="delNodo"
        @exp-nodo="expNodo"
      ></tree-carpeta>
    </ul>

    <b-modal
      ref="carpeta"
      v-bind:title="carpeta.id ? 'Editar Carpeta' : 'Agregar Carpeta'"
      centered
      hide-backdrop
    >
      <div class="row">
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Nombre: </label>
          <div class="col-md-7">
            <input
              class="form-control"
              type="text"
              v-model="carpeta.nombre"
              :class="estatus($v.carpeta.nombre)"
            />
            <div class="errores" v-if="!$v.carpeta.nombre.required">
              Requerido
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Descripción: </label>
          <div class="col-md-7">
            <textarea
              class="form-control"
              v-model="carpeta.descripcion"
            ></textarea>
          </div>
        </div>
      <div class="form-group row col-md-12">
        <label class="col-md-5 col-form-label">Imagen: </label>
        <div class="col-md-7">
          <div style="max-width: 300px; max-height: 300px; overflow-y: auto">
            <archivo-imagen v-model="carpeta.foto"></archivo-imagen>
          </div>
        </div>
      </div>
      </div>
      <br />
      <template #modal-footer="{ hide }">
        <button v-on:click="guardar" class="btn btn-primary">Guardar</button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    <b-modal
      ref="archivo"
       size="lg"
      v-bind:title="archivo.id ? 'Editar Archivo' : 'Agregar Archivo'"
      centered
      hide-backdrop
    >
      

      <div class="form-group row col-md-12">
        <label class="col-md-5 col-form-label">Nombre: </label>
        <div class="col-md-7">
          <input
            class="form-control"
            type="text"
            v-model="archivo.nombre"
            :class="estatus($v.archivo.nombre)"
          />
          <div class="errores" v-if="!$v.archivo.nombre.required">Requerido</div>
        </div>
      </div>

      <div class="form-group row col-md-12">
        <label class="col-md-5 col-form-label">Descripción:</label>
        <div class="col-md-7">
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="archivo.descripcion"
            class="form-control"
            :class="estatus($v.archivo.descripcion)"
          />
          <div class="errores" v-if="!$v.archivo.descripcion.required">Requerido</div>
        </div>
      </div>
      <div class="form-group row col-md-12">
        <label class="col-md-5 col-form-label">Archivo: </label>
        <div class="col-md-7">
          <div style="max-width: 300px; max-height: 300px; overflow-y: auto">
            <archivo-pdf v-model="archivo.archivo"></archivo-pdf>
          </div>
        </div>
      </div>
      <div class="form-group row col-md-12">
        <label class="col-md-5 col-form-label">Imagen: </label>
        <div class="col-md-7">
          <div style="max-width: 300px; max-height: 300px; overflow-y: auto">
            <archivo-imagen v-model="archivo.foto"></archivo-imagen>
          </div>
        </div>
      </div>
      <br />
      <template #modal-footer="{ hide }">
        <button v-on:click="guardarArchivo" class="btn btn-primary">
          Guardar
        </button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
  </div>
</template>    
<script>
import TreeCarpeta from "@/components/TreeCarpeta.vue";
import { API_URL } from "@/api";
import ArchivoImagen from "@/components/ArchivoImagen.vue";
import ArchivoPdf from "@/components/ArchivoPdf.vue";
import api from "@/api";
import { required } from "vuelidate/lib/validators";

// define the tree-item component
export default {
  components: { TreeCarpeta, ArchivoImagen, ArchivoPdf },
  data() {
    return {
      ruta: API_URL,
      stipo: 1,
      id: null,
      idCarpeta: null,
      carpeta: {},
      archivo: {},
      padre: {},
      carpetas: {},
      municipios: [],
      localidades: [],
      partidos: [],
      guardando: false,
    };
  },
  validations() {
    return {
      carpeta: {
        nombre: {
          required,
        },
      },
      archivo: {
        nombre: {
          required,
        },
        descripcion: { required: false },
      },
    };
  },
  avance(progreso) {
    this.avanceGuardando = Math.round((progreso.loaded * 100) / progreso.total);
  },
  methods: {
    guardar: function () {
      this.$v.carpeta.$touch();
      if (!this.$v.carpeta.$invalid) {
        this.guardando = true;
        api
          .guardarCarpeta(this.carpeta, this.avance)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.carpeta.id = datos.id;
              this.carpeta.ruta = datos.ruta;
              if (!("children" in this.padre)) {
                this.padre.children = [];
              }
              this.padre.children.push(this.carpeta);
              this.carpeta = {};
              this.actualiza();
              //alert(datos.mensaje);
              this.$refs.carpeta.hide();
            } else {
              let msg = "";
              if (datos != null) {
                msg = datos.mensaje;
              }
              alert("Error al guardar la información: " + msg);
            }
            this.guardando = false;
          })
          .catch((error) => {
            alert("Error al guardar la información: " + error);
            this.guardando = false;
          });
      } else {
        alert("Faltan datos por registrar o existen datos incorrectos");
      }
    },
    guardarArchivo: function () {
      this.$v.archivo.$touch();
      if (!this.$v.archivo.$invalid) {
        api
          .guardarCarpetaArchivo(this.archivo)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.archivo.id = datos.multimedio_carpeta.id;
              if (this.padre != null) {
                if (!("archivos" in this.padre)) {
                  this.padre.archivos = [];
                }
                this.padre.archivos.push(this.archivo);
              }
              this.archivo = {};
              this.actualiza();
              //alert(datos.mensaje);
              this.$refs.archivo.hide();
            } else {
              let msg = "";
              if (datos != null) {
                msg = datos.mensaje;
              }
              alert("Error al guardar la información: " + msg);
            }
            this.guardando = false;
          })
          .catch((error) => {
            alert("Error al guardar la información: " + error);
            this.guardando = false;
          });
      } else {
        alert("Faltan datos por registrar o existen datos incorrectos");
      }
    },
    addArchivo: function (item) {
      this.padre = item;
      this.archivo = {
        carpeta_id: item.id,
      };
      this.$refs.archivo.show();
    },
    openArchivo: function (item) {
      console.log(item);
      window.open(`${API_URL}/archivo/${item.archivo.ruta}`, "_blank");
      //w.document.write('<title>My PDF File Title</title>');
    },
    editArchivo: function (archivo, item) {
      this.padre = item;
      this.archivo = Object.assign({}, archivo);
      if (archivo.municipio_id != null) {
        this.actualizaLocalidades(archivo.municipio_id);
      } else {
        this.localidades = [];
      }
      this.$refs.archivo.show();
    },
    delArchivo: function (archivo) {
      if (confirm("¿Confirma eliminar a " + archivo.nombre)) {
        api.eliminarCarpetaArchivo(archivo.id).then((response) => {
          
          if (response.data.respuesta) {
            this.actualiza();
          } else {
            alert(response.data.mensaje);
          }
        });
      }
    },
    addNodo: function (item) {
      this.padre = item;
      this.carpeta = {
        padre_id: item.id,
        ruta_padre: item.ruta,
      };
      this.enlaces = [];
      this.$refs.carpeta.show();
    },
    editFiles: function (item) {
      window.open(`carpeta/${item.id}`);
    },
    editNodo: function (item) {
      this.carpeta = Object.assign({}, item);
      this.$refs.carpeta.show();
    },
    expNodo: function (item) {
      this.$router.push({ path: `/carpeta/${item.id}` });
    },
    regresar: function () {
      this.$router.push({ path: "/carpeta" });
    },
    delNodo: function (item) {
      if (confirm("¿Confirma eliminar a " + item.nombre)) {
        api.eliminarCarpeta(item.id).then((response) => {
          
          if (response.data.respuesta) {
            this.actualiza();
          } else {
            alert(response.data.mensaje);
          }
        });
      }
    },

    //Metodos de actualización
    actualiza: function () {
      var id = this.$route.params.id;
      if (typeof id == 'undefined') {
        id = 0;
      } 
      this.id = id;
      api.listarCarpeta(id).then((response) => {
        if (response.data) {
          this.carpetas = response.data;
        }
      });
    },

    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
    
  },
  computed: {
    imagen_partido(partido) {
      if (partido.imagen != null) {
        return `background-image:url(${this.ruta}/archivo/${partido.imagen.ruta})`;
      }
      return '';
    }
  },
  mounted() {
    this.actualiza();
    this.$watch(
      () => this.$route.params,
      () => {
        this.actualiza();
      }
    );
  },
  /*watch:{
    $route(){
      this.actualiza();
    }
  },*/ 
};
</script>
<style>
li {
  list-style: none;
  margin-left: 0px;
}

ul {
  padding-inline-start: 20px;
}
</style>
