<template>
  <li>
    <div
      class="tree-item-container"
      :class="'nivel' + ((item.id % 5) + 1)"
    >
      <a
        href="javascript:;"
        @click="toggle"
        class="toggle-icon"
        v-if="isFolder"
      >
        <font-awesome-icon v-show="!isOpen" icon="angle-right" />
        <font-awesome-icon v-show="isOpen" icon="chevron-down" />
      </a>

      <font-awesome-icon icon="briefcase" class="folder-icon" />
      <a @click="toggle" href="javascript:;" class="item-name">
        {{ item.nombre }}
      </a>

      <div v-if="item.actor_id > 0" class="actor-info">
        <font-awesome-icon icon="user" /> {{ item.actor.nombre }}
      </div>

      <!-- Botones de acción -->
      <div class="action-buttons">
        <a href="javascript:;" @click="$emit('add-nodo', item)" class="action-btn">
          <font-awesome-icon icon="plus-square" />
        </a>
        <a href="javascript:;" @click="$emit('add-actor', item)" v-if="item.id > 0" class="action-btn">
          <font-awesome-icon icon="user" />
        </a>
        <a href="javascript:;" @click="$emit('edit-nodo', item)" v-if="item.id > 0" class="action-btn">
          <font-awesome-icon icon="edit" />
        </a>
        <a href="javascript:;" @click="$emit('del-nodo', item)" v-if="item.id > 0" class="action-btn">
          <font-awesome-icon icon="trash-alt" />
        </a>
      </div>
    </div>
    <ul v-show="isOpen" v-if="isFolder" class="child-items">
      <tree-item
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        @add-actor="$emit('add-actor', $event)"
        @edit-actor="$emit('edit-actor', $event)"
        @del-actor="$emit('del-actor', $event)"
        @add-nodo="$emit('add-nodo', $event)"
        @edit-nodo="$emit('edit-nodo', $event)"
        @del-nodo="$emit('del-nodo', $event)"
        @exp-nodo="$emit('exp-nodo', $event)"
      ></tree-item>

      <li v-for="actor in item.actores" :key="actor.id" class="actor-item">
        <div class="actor-details">
          <font-awesome-icon icon="user" class="actor-icon" v-if="actor.foto == null" />
          <img :src="`${ruta}/archivo/${actor.foto.ruta}`" class="actor-img" v-if="actor.foto != null" />
          <a @click="$emit('edit-actor', actor)" href="javascript:;" class="actor-name">
            {{ actor.puesto }} {{ actor.nombre }} {{ actor.paterno }} {{ actor.materno }}
          </a>
          <div class="action-buttons">
            <a href="javascript:;" @click="$emit('edit-actor', actor, item)" class="action-btn">
              <font-awesome-icon icon="edit" />
            </a>
            <a href="javascript:;" @click="$emit('del-actor', actor)" class="action-btn">
              <font-awesome-icon icon="trash-alt" />
            </a>
          </div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import { API_URL } from "@/api";
export default {
  name: "TreeItem",
  props: {
    item: Object,
  },
  data() {
    return {
      ruta: API_URL,
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      } else {
        this.$emit("edit-nodo", this.item);
      }
    },
  },
  computed: {
    isFolder() {
      return (this.item.children && this.item.children.length) || (this.item.actores && this.item.actores.length);
    },
  },
};
</script>

<style scoped>
.tree-item-container {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.folder-icon,
.actor-icon,
.toggle-icon {
  color: #ddd;
  margin-right: 8px;
}

.item-name {
  font-size: 1.1rem;
  color: #fff;
  flex-grow: 1;
}

.actor-info {
  margin-left: 15px;
  color: #ccc;
}

.action-buttons {
  display: flex;
  gap: 5px;
}

.action-btn {
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

.action-btn:hover {
  color: #aaa;
}

.actor-item {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 8px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.actor-img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.actor-name {
  color: #fff;
  margin-right: auto;
}

.child-items {
  margin-left: 20px;
}
</style>
