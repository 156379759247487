import Vue from 'vue'
import { Buffer } from 'buffer';

export const EventBus = new Vue()

export function jwtValido(jwt) {
    if (jwt == null) {
        return false;
    }
    if (jwt.split('.').length < 3) {
        return false
    }
    /*
    console.log("JWT:");
    console.log(jwt);
    console.log(Buffer.from(jwt.split('.')[1], 'base64').toString());
    */
    const data = JSON.parse(Buffer.from(jwt.split('.')[1], 'base64').toString())
    //console.log(new Date(data.exp * 1000))
    const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
    const now = new Date()
    return now < exp
}
export function tiempoMinimoPaso(jwt) {
    if (jwt == null) {
        return false;
    }
    if (jwt.split('.').length < 3) {
        return false
    }
    const data = JSON.parse(Buffer.from(jwt.split('.')[1], 'base64').toString())
    //console.log(new Date(data.exp * 1000))
    const iat = new Date(data.iat * 1000 + 60 * 1 * 1000) // 5 minutos //TODO: Ajustar tiempo
    //const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
    const now = new Date();
    //console.log(iat);
    //console.log(now);
    return now > iat;
}

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    )
}