<template>
  <div>
    <h4>Listado de tipos de actores</h4>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Id</th>
          <th>Nombre</th>
          <th>Acciones</th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td>{{ elemento.id }}</td>
          <td>{{ elemento.tipoactor }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del tipo de actor</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Nombre tipo actor</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.tipoactor"
            class="form-control"
            :class="estatus($v.actual.tipoactor)"
          />
          <div class="errores" v-if="!$v.actual.tipoactor.required">Requerido</div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  data() {
    return {
      titulo: "Agregar tipo actor",
      listado: [],
      buscando: false,
      actual: {area:null,responsable:null,cargo:null,email:null,telefono:null, telefonod:null },
    };
  },
  validations() {
    return {
      actual: {
        tipoactor: {
          required,
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo = "Agregar tipo de actor";
      this.actual = {};
      this.$refs.detalle.show();
    },
    editar (elemento) {
      this.titulo = "Editar tipo de actor",
      this.actual = Object.assign({}, elemento);
      this.$refs.detalle.show();
    },
    eliminar (elemento) {
      if ( confirm("Confirma eliminar el tipo de actor: "+elemento.tipoactor)) {
        api.eliminarTipoActor(elemento.id)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizar();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {this.$parent.aviso("No se pudo eliminar: "+error);});
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        api
          .guardarTipoActor(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizar();
            } else {
              this.$parent.error("Error al guardar la información "+r.mensaje);
            }
          })
          .catch((error) => {
              this.$parent.error("Error al guardar la información "+error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizar() {
      api.listarTipoActores().then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        } else {
          this.listado = [];
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizar();
  },
};

</script>

