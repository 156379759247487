<template>
  <div class="archivo-pdf-container">
    <div v-if="contenido == null" class="sin-archivo">
      <font-awesome-icon icon="exclamation-circle" />
      Sin archivo
    </div>
    <div v-else class="archivo-link">
      <a href="javascript:;" @click="abrirArchivo">
        <font-awesome-icon icon="file-pdf" />
        Documento.pdf
      </a>
    </div>
    <!-- Botones de acción -->
    <div class="action-buttons">
      <button class="btn action-btn" @click="subirArchivo">
        <font-awesome-icon icon="file-pdf" /> Subir PDF
      </button>
      <button v-if="contenido" class="btn action-btn" @click="quitarArchivo">
        <font-awesome-icon icon="trash" /> Eliminar
      </button>
    </div>
  </div>
</template>

<script>
import { API_URL } from "@/api";
export default {
  name: "ArchivoPdf",
  props: {
    value: {},
  },
  data() {
    return {
      API_URL: API_URL,
      inputfile: null,
      contenido: this.value,
    };
  },
  methods: {
    subirArchivo() {
      this.inputfile.onchange = (e) => {
        const archivo = e.target.files[0];
        if (archivo.type != "application/pdf") {
          alert("El archivo debe ser de tipo PDF.");
          return;
        }
        if (archivo.size > 1024 * 1024 * 12) {
          alert("Solo se permiten archivos de hasta 12 MB.");
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(archivo);
        reader.onload = (readerEvent) => {
          this.contenido = readerEvent.target.result;
        };
      };
      this.inputfile.click();
    },
    abrirArchivo() {
      if (this.contenido instanceof Object) {
        window.open(`${API_URL}/archivo/${this.contenido.ruta}`, "_blank");
      } else {
        const win = window.open("", "_blank");
        win.document.write(`
          <html><body style="margin:0">
            <embed width="100%" height="100%" src="${this.contenido}" type="application/pdf" />
          </body></html>
        `);
      }
    },
    quitarArchivo() {
      this.contenido = null;
    },
  },
  watch: {
    contenido() {
      this.$emit("input", this.contenido);
    },
    value(valor) {
      this.contenido = valor;
    },
  },
  created() {
    this.inputfile = document.createElement("input");
    this.inputfile.type = "file";
    this.inputfile.accept = "application/pdf";
  },
};
</script>

<style scoped>
.archivo-pdf-container {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  gap: 10px;
}

.sin-archivo {
  color: #e74c3c;
}

.archivo-link {
  color: #3498db;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-btn {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.action-btn:hover {
  background-color: #555;
  border-color: #aaa;
}
</style>
