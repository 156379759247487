<template>
  <div>
    <h4>Seguimiento a correspondencia</h4>
    <div class="grupo">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Mes:</label>
          <input class="form-control" type="month" v-model="busqueda.mes" />
        </div>
      </div>
      <div class="text-right">
        <b-button @click="actualizaCorrespondencia">Buscar</b-button>
      </div>
    </div>

    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>No</th>
          <th>Fecha y hora</th>
          <th>No. oficio</th>
          <th>Asunto</th>
          <th>Nombre</th>
          <th>Dirigido a</th>
          <th>Observaciones</th>
          <th></th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td>{{ elemento.id }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              {{ elemento.fecha }}</a
            >
          </td>
          <td>{{ elemento.no_oficio }}</td>
          <td>{{ elemento.asunto }}</td>
          <td>{{ elemento.nombre }}</td>
          <td>{{ elemento.dirigido }}</td>
          <td>{{ elemento.observaciones }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detallec" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos de la correspondencia</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Fecha y hora</label>
          <b-input v-model.trim="actual.fecha" type="datetime-local"            
            :class="estatus($v.actual.fecha)"
          />
          
          <div class="errores" v-if="!$v.actual.fecha.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>No. oficio</label>
          <b-input v-model.trim="actual.no_oficio"            
            :class="estatus($v.actual.no_oficio)"
          />
          <div class="errores" v-if="!$v.actual.no_oficio.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Asunto</label>
          <b-input v-model.trim="actual.asunto"            
            :class="estatus($v.actual.asunto)"
          />
          <div class="errores" v-if="!$v.actual.asunto.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Nombre</label>
          <b-input v-model.trim="actual.nombre"            
            :class="estatus($v.actual.nombre)"
          />
          <div class="errores" v-if="!$v.actual.nombre.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Dirigido</label>
          <b-input v-model.trim="actual.dirigido"            
            :class="estatus($v.actual.dirigido)"
          />
          <div class="errores" v-if="!$v.actual.dirigido.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Observaciones</label>
          <b-textarea rows="4"
            v-model.trim="actual.observaciones"
            class="form-control"
            :class="estatus($v.actual.observaciones)"
          />
          <div class="errores" v-if="!$v.actual.observaciones.required">Requerido</div>
        </div>

      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    
    
    <b-modal v-model="guardando" title="Guardando" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>      
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import BarraProgreso from "@/components/BarraProgreso";

export default {
  name: "Correspondencia",
  components: { BarraProgreso  },
  data() {
    return {
      titulo: "Agregar correspondencia",
      busqueda: { mes: moment(Date.now()).format("YYYY-MM") },
      listado: [],
      guardando: false,
      avanceGuardando: 0,
      persona: {},
      personas: [],
      indicePersona: 0,
      localidades: [],
      municipios: [],
      buscar: {},

      posibles: [],
      actual: {
        nombre: null,
        descripcion: null,
        activo: true,
      },
    };
  },
  validations() {
    return {
      actual: {
        fecha: {
          required,
        },
        no_oficio: {
          required:false,
        },
        asunto: {
          required,
        },
        nombre: {
          required,
        },
        dirigido: {
          required,
        },
        observaciones: {
          required,
        },
      },
      
    };
  },
  methods: {

    editar(elemento) {
      this.titulo = "Editar correspondencia";
      this.personas = elemento.personas || [];
      this.personasEliminar = [];
      this.posibles = [];

      let ahora = Date.parse(elemento.fecha);
      this.actual = Object.assign({}, elemento);
      this.actual.fecha = moment(ahora).format("YYYY-MM-DDTHH:mm:ss"),

      this.$refs.detallec.show();
    },
    
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        var enviar = Object.assign({}, this.actual);
        api
          .guardarCorrespondencia(enviar)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detallec.hide();
              this.actualizaCorrespondencia();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    abrirDetalle() {
      this.$refs.detallec.show();
    },
    actualizaCorrespondencia() {
      api.listarCorrespondencia(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        }
      });
    },

    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaCorrespondencia();
  },
};
</script>

