<template>
    <div>
      <h1>Visualización de Incidencias</h1>
      <!-- Insertamos el iframe para mostrar el dashboard de Power BI -->
      <iframe 
        width="100%" 
        height="600px" 
        :src="dashboardUrl" 
        frameborder="0" 
        allowFullScreen="true">
      </iframe>
    </div>
  </template>
  
  <script>
  export default {
    name: "VisualizacionIncidencias",
    data() {
      return {

        dashboardUrl: "https://app.powerbi.com/view?r=eyJrIjoiODYxMTkzN2ItMzA5OS00M2NlLWIzYmUtYzZjNzNjZGIwNjcyIiwidCI6ImFlNzJhZmQzLTkyZDktNGY0MS1hOGE2LTA5ZTZiOWIxOGIwYiJ9"
      };
    }
  };
  </script>
  
  <style scoped>
  /* Estilos adicionales para personalizar el iframe */
  iframe {
    border: none;
    width: 100%;
    height: 80vh;
  }
  h1{
    color:azure;
  }
  </style>
  
  
  
  
  
  