import axios from 'axios'
import store from '../store'
//import * as utils from '@/utils'

export const API_URL =  'https://caoticadigital.com/api';
export const PDF_URL = (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://127.0.0.1:5000/api/formatos' : '/api/formatos';
export const ARCHIVOS_URL = (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://127.0.0.1:5000/api/archivos' : '/api/archivos';
export const TIEMPO_SESION = 10;
const api = {
    iniciarSesion(datos) {
        return axios.post(`${API_URL}/sesion/iniciar/`, datos);
    },
    renovarSesion() {
        return axios.post(`${API_URL}/sesion/renovar/`);
    },
    //////////////////////////////////////////////
    listarGraficaAvance(busqueda) {
        return axios.get(`${API_URL}/reporte/avancesg/${busqueda.semana}/${busqueda.id_grupo}/${busqueda.id_usuario}/`);
    },
    //////////////////////////////////////////////
    listarMunicipios() {
        return axios.get(`${API_URL}/municipio/listar/`);
    },
    listarLocalidades(municipio_id) {
        return axios.get(`${API_URL}/localidad/listar/${municipio_id}`);
    },
    //////////////////////////////////////////////
    listarCorrespondencia(busqueda) {
        let vars = {};
        vars.params = busqueda;        
        return axios.get(`${API_URL}/correspondencia/listar/`, vars);
    },
    guardarCorrespondencia(datos) {
        return axios.post(`${API_URL}/correspondencia/guardar/`, datos);
    },
    eliminarCorrespondencia(id) {
        return axios.delete(`${API_URL}/correspondencia/eliminar/${id}`);
    },
    obtenerUsuarioLogueado() {
        return axios.get(`${API_URL}/usuario/perfil`);
      },
    //////////////////////////////////////////////
    listarAudiencias(busqueda) {
        let vars = {};
        vars.params = busqueda;
        return axios.get(`${API_URL}/audiencia/listar/`, vars);
    },
    guardarAudiencia(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/audiencia/guardar/`, datos, vars);
    },
    eliminarAudiencia(id) {
        return axios.delete(`${API_URL}/audiencia/eliminar/${id}`);
    },
    guardarSeguimientoAudiencia(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/audiencia/guardar_seguimiento/`, datos, vars);
    },
    listarSeguimientoAudiencia(id) {
        return axios.get(`${API_URL}/audiencia/seguimiento/${id}`);
    },
    //////////////////////////////////////////////
    listarFichas(busqueda) {
        let vars = {};
        vars.params = busqueda;
        return axios.get(`${API_URL}/ficha/listar/`, vars);
    },
    guardarFicha(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/ficha/guardar/`, datos, vars);
    },
    obtenerUsuarioLogueado() {
        return axios.get(`${API_URL}/usuario/perfil`);
      },
    eliminarFicha(id) {
        return axios.delete(`${API_URL}/ficha/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarTarjetas(busqueda) {
        let vars = {};
        vars.params = busqueda;
        return axios.get(`${API_URL}/tarjeta/listar/`, vars);
    },
    guardarTarjeta(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/tarjeta/guardar/`, datos, vars);
    },
    eliminarTarjeta(id) {
        return axios.delete(`${API_URL}/tarjeta/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarPartidosPoliticos() {
        return axios.get(`${API_URL}/partidopolitico/listar/`);
    },
    guardarPartidoPolitico(datos) {
        return axios.post(`${API_URL}/partidopolitico/guardar/`, datos);
    },
    eliminarPartidoPolitico(id) {
        return axios.delete(`${API_URL}/partidopolitico/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarReportes() {
        return axios.get(`${API_URL}/reporte/listar/`);
    },
    guardarReporte(datos) {
        return axios.post(`${API_URL}/reporte/guardar/`, datos);
    },
    eliminarReporte(id) {
        return axios.delete(`${API_URL}/reporte/eliminar/${id}`);
    },
    listarEntradas(idReporte) {
        return axios.get(`${API_URL}/reporte/entradas/${idReporte}/`);
    },
    buscarEntradas(busqueda) {
        let vars = {};
        vars.params = busqueda;
        return axios.get(`${API_URL}/reporte/entradasq/`, vars);
    },
    eliminarEntrada(id) {
        return axios.delete(`${API_URL}/reporte/eliminar_entrada/${id}`);
    },
    eliminarMultimedioEntrada(id) {
        return axios.delete(`${API_URL}/reporte/eliminar_multimedio_entrada/${id}`);
    },    
    listarMisReportes() {
        return axios.get(`${API_URL}/reporte/mis/`);
    },
    guardarEntrada(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/reporte/guardar_entrada/`, datos, vars);
    },
    //////////////////////////////////////////////
    listarUsuarios() {
        return axios.get(`${API_URL}/usuario/listar/`);
    },
    listarUsuarioRol(roles) {
        let rs = roles.join(",");
        return axios.get(`${API_URL}/usuario/listar_rol/${rs}/`);
    },
    guardarUsuario(datos) {
        return axios.post(`${API_URL}/usuario/guardar/`, datos);
    },
    eliminarUsuario(id) {
        return axios.delete(`${API_URL}/usuario/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarActores() {
        return axios.get(`${API_URL}/actor/listar/`);
    },
    guardarActor(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/actor/guardar/`, datos, vars);
    },
    eliminarActor(id) {
        return axios.delete(`${API_URL}/actor/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    //////////////////////////////////////////////
    listarFunciones() {
        return axios.get(`${API_URL}/funcion/listar/`);
    },
    guardarFuncion(datos) {
        return axios.post(`${API_URL}/funcion/guardar/`, datos);
    },
    eliminarFuncion(id) {
        return axios.delete(`${API_URL}/funcion/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarCarpetaSelect() {
        return axios.get(`${API_URL}/carpeta/lista_select/`);
    },
    listarCarpeta(valor) {
        return axios.get(`${API_URL}/carpeta/listar/${valor}/`);
    },
    guardarCarpeta(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/carpeta/guardar/`, datos, vars);
    },
    eliminarCarpeta(id) {
        return axios.delete(`${API_URL}/carpeta/eliminar/${id}`);
    },    
    guardarCarpetaArchivo(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/carpeta/guardar_archivo/`, datos, vars);
    },
    eliminarCarpetaArchivo(id) {
        return axios.delete(`${API_URL}/carpeta/eliminar_archivo/${id}`);
    },    
    //////////////////////////////////////////////
    listarOrganigramaSelect() {
        return axios.get(`${API_URL}/organigrama/lista_select/`);
    },
    listarOrganigrama(valor) {
        return axios.get(`${API_URL}/organigrama/listar/${valor}/`);
    },
    guardarOrganigrama(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/organigrama/guardar/`, datos, vars);
    },
    eliminarOrganigrama(id) {
        return axios.delete(`${API_URL}/organigrama/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarTipoTarjetas(tipo = '') {
        return axios.get(`${API_URL}/tipotarjeta/listar/${tipo}`);
    },
    guardarTipoTarjeta(datos) {
        return axios.post(`${API_URL}/tipotarjeta/guardar/`, datos);
    },
    eliminarTipoTarjeta(id) {
        return axios.delete(`${API_URL}/tipotarjeta/eliminar/${id}`);
    },    
    //////////////////////////////////////////////
    listarTipoEventos() {
        return axios.get(`${API_URL}/tipoevento/listar/`);
    },
    guardarTipoEvento(datos) {
        return axios.post(`${API_URL}/tipoevento/guardar/`, datos);
    },
    eliminarTipoEvento(id) {
        return axios.delete(`${API_URL}/tipoevento/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarTipoConflicto() {
        return axios.get(`${API_URL}/tipoconflicto/listar/`);
    },
    guardarTipoConflicto(datos) {
        return axios.post(`${API_URL}/tipoconflicto/guardar/`, datos);
    },
    eliminarTipoConflicto(id) {
        return axios.delete(`${API_URL}/tipoconflicto/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarEstatusAudiencia() {
        return axios.get(`${API_URL}/estatusaudiencia/listar/`);
    },
    guardarEstatusAudiencia(datos) {
        return axios.post(`${API_URL}/estatusaudiencia/guardar/`, datos);
    },
    eliminarEstatusAudiencia(id) {
        return axios.delete(`${API_URL}/estatusaudiencia/eliminar/${id}`);
    },//////////////////////////////////////////////
    listarTipoInformacion() {
        return axios.get(`${API_URL}/tipoinformacion/listar/`);
    },
    guardarTipoInformacion(datos) {
        return axios.post(`${API_URL}/tipoinformacion/guardar/`, datos);
    },
    eliminarTipoInformacion(id) {
        return axios.delete(`${API_URL}/tipoinformacion/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarTipoActores() {
        return axios.get(`${API_URL}/tipoactor/listar/`);
    },
    guardarTipoActor(datos) {
        return axios.post(`${API_URL}/tipoactor/guardar/`, datos);
    },
    eliminarTipoActor(id) {
        return axios.delete(`${API_URL}/tipoactor/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarRoles() {
        return axios.get(`${API_URL}/rol/listar/`);
    },
    listarRolesDetalle() {
        return axios.get(`${API_URL}/rol/listar_detalle/`);
    },
    guardarRol(datos) {
        return axios.post(`${API_URL}/rol/guardar/`, datos);
    },
    eliminarRol(id) {
        return axios.delete(`${API_URL}/rol/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarBitacora(busqueda) {
        let vars = {};
        vars.params = busqueda;
        return axios.get(`${API_URL}/bitacora/listar/`, vars);
    },
    listarErrores(busqueda) {
        let vars = {};
        vars.params = busqueda;
        return axios.get(`${API_URL}/errores/listar/`, vars);
    },
    token() {
        let jwt = store.getters.token

        return { headers: { Authorization: `Bearer: ${jwt}` } }
    },
}
//var _controlTiempo = null;
axios.interceptors.request.use((config) => {
    let jwt = store.getters.token;
    config.headers.Authorization = `Bearer: ${jwt}`;
    //console.log(config);
    /*if (!config.url.includes('/sesion/')) {
        if (utils.tiempoMinimoPaso(jwt)) {
            if (_controlTiempo != null) {
                clearTimeout(_controlTiempo);
            }
            _controlTiempo = setTimeout(() => store.dispatch("renovarSesion"), 5000);
        }
    }*/
    return config;
});
export default api;