<template>
  <li>
    <div
      class="bg-light border-bottom p-1"
      :class="'nivel' + ((item.id % 5) + 1)"
    >
      <a
        href="javascript:;"
        @click="toggle"
        style="width: 10px; margin-left: 0px"
        v-if="isFolder"
        >
        <font-awesome-icon v-show="!isOpen" icon="angle-right" style="width: 12px;" />
        <font-awesome-icon v-show="isOpen" icon="chevron-down" style="width: 12px;" />
      </a>

      <font-awesome-icon icon="folder" v-if="item.foto == null" size="lg" :style="{'margin-left': isFolder ? '0px': '12px'}"/>
      <img
          :src="`${ruta}/archivo/${item.foto.ruta}`"
          :style="{'margin-left': isFolder ? '0px': '12px'}"
          height="20"
          v-if="item.foto != null"
        />

      <a @click="toggle" href="javascript:;">
        {{ item.nombre }} 
      </a>
      <div v-if="item.archivo_id > 0">
        <font-awesome-icon icon="user" /> {{ item.archivo.nombre }}
      </div>
      <a href="javascript:;" @click="$emit('add-nodo', item)"
        ><font-awesome-icon size="lg" fixed-width icon="plus-square" />
      </a>
      <a
        href="javascript:;"
        @click="$emit('add-archivo', item)"
        v-if="item.id > 0"
        ><font-awesome-icon fixed-width size="lg" icon="file-import" />
      </a>
      <a
        href="javascript:;"
        @click="$emit('edit-nodo', item)"
        v-if="item.id > 0"
        ><font-awesome-icon fixed-width size="lg" icon="edit"
      /></a>
      <a href="javascript:;" @click="$emit('del-nodo', item)" v-if="item.id > 0"
        ><font-awesome-icon fixed-width size="lg" icon="trash-alt"
      /></a>
      <a href="javascript:;" @click="$emit('exp-nodo', item)" v-if="false"
        ><font-awesome-icon fixed-width size="lg" icon="file-export"
      /></a>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
      <tree-item
        class="item"
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        @add-archivo="$emit('add-archivo', $event)"
        @edit-archivo="$emit('edit-archivo', $event)"
        @del-archivo="$emit('del-archivo', $event)"
        @open-archivo="$emit('open-archivo', $event)"
        @add-nodo="$emit('add-nodo', $event)"
        @edit-nodo="$emit('edit-nodo', $event)"
        @del-nodo="$emit('del-nodo', $event)"
        @exp-nodo="$emit('exp-nodo', $event)"
      ></tree-item>
      <li v-show="isOpen" v-for="archivo in item.archivos" v-bind:key="archivo.id">
        <div
          class="bg-light border-bottom p-1"
          :class="'nivel' + ((item.id % 5) + 1)"
        >
          <font-awesome-icon
            icon="file"
            size="lg"
            style="margin-left: 12px;"
            fixed-width
            v-if="archivo.foto == null"
          />
          <img
            :src="`${ruta}/archivo/${archivo.foto.ruta}`"
            style="margin-left: 12px;"
            height="20"
            v-if="archivo.foto != null"
          />
          <a @click="$emit('open-archivo', archivo)" href="javascript:;">
            {{ archivo.nombre }}
          </a>
          <a
            href="javascript:;"
            @click="$emit('edit-archivo', archivo, item)"
            v-if="item.id > 0"
            ><font-awesome-icon fixed-width size="lg" icon="edit"
          /></a>
          <a
            href="javascript:;"
            @click="$emit('del-archivo', archivo)"
            v-if="item.id > 0"
            ><font-awesome-icon fixed-width size="lg" icon="trash-alt"
          /></a>
        </div>
      </li>
    </ul>
  </li>
</template>
<script>
import { API_URL } from "@/api";
export default {
  name: "TreeItem",
  //template: "#item-template",
  props: {
    item: Object,
  },
  data: function () {
    return {
      ruta: API_URL,
      isOpen: false,
    };
  },
  methods: {
    toggle: function () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      } else {
        this.$emit("edit-nodo", this.item);
      }
    },
  },
  computed: {
    isFolder: function () {
      return (
        (this.item.children && this.item.children.length) ||
        (this.item.archivos && this.item.archivos.length)
      );
    },
  },
};
</script>