<template>
  <div>
    <h4>Información del reporte</h4>
    <div class="text-right">
      <b-button @click="agregarEntrada">Agregar</b-button>&nbsp;
      <b-button to="/reportes">Regresar</b-button>
    </div>

    <div style="overflow-y: auto">
      <div class="grupo" v-for="entrada in entradas" :key="entrada.id">
        <table>
          <tr>
            <td>Código:</td>
            <td>
              <b><span v-b-tooltip.hover v-bind:title="entrada.usuario.nombre+' '+entrada.usuario.paterno+' '+entrada.usuario.materno">{{ entrada.usuario.clave || "Sin código" }}</span></b>
            </td>
          </tr>
          <tr>
            <td>Municipio:</td>
            <td>
              <b>{{ entrada.municipio != null ? entrada.municipio.municipio : "Sin municipio" }}</b>
            </td>
          </tr>
          <tr>
            <td>Comunidad:</td>
            <td>
              <b>{{ entrada.comunidad != null ? entrada.comunidad.comunidad : "Sin comunidad" }}</b>
            </td>
          </tr>
          <tr>
            <td>Ubicación:</td>
            <td>
              <b>{{ entrada.lugar || "Sin ubicación" }}</b>
            </td>
          </tr>
          <tr>
            <td>Fecha:</td>
            <td>
              <b>{{ entrada.fecha }}</b>
            </td>
          </tr>
          <tr>
            <td>Ubicación:</td>
            <td><a href="javascript:;" @click="verUbicacion(entrada)"><b>Ver ubicación</b></a></td>
          </tr>
        </table>
        <div class="border border-warning" style="min-height: 80px">
          {{ entrada.descripcion }}
        </div>
        <div style="display: flex">
          <div
            v-for="m in entrada.multimedios"
            :key="m.id"
            style="width: 50px; height: 40px; padding: 2px"
          >
            <a href="javascript:;" @click="verArchivo(m)">
              <img
                :src="`${ruta}/archivo/${m.ruta}`"
                width="100%"
                style="object-fit: cover"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="entrada" title="Agregar información">
      <p class="text-justify">
        <b>Información</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Descripción</label>
          <b-textarea
            v-model.trim="entrada.descripcion"
            class="form-control"
            :class="estatus($v.entrada.descripcion)"
          />
          <div class="errores" v-if="!$v.entrada.descripcion.required">
            Requerido
          </div>
        </div>
        <div style="display: flex">
          <div
            v-for="m in entrada.multimedios"
            :key="m.id"
            style="width: 50px; height: 40px; padding: 2px"
          >
            <a href="javascript:;" @click="verArchivo(m)">
              <img
                :src="`${ruta}/archivo/${m.ruta}`"
                width="100%"
                style="object-fit: cover"
              />
            </a>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="agregarImagen()">
          Agregar Imagen
        </b-button>
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal size="lg" ref="mapa" title="Ubicación" @show="mapaMostrado">
      <div class="col">
        <l-map ref="mapaL" style="height: 400px;" :zoom="zoom" :center="punto">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="punto"></l-marker>
        </l-map>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal size="lg" ref="archivo" :title="archivo.nombre">
      <div class="col">
        <img :src="`${ruta}/archivo/${archivo.ruta}`" class="img-fluid" />
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal ref="verimagend" :title="archivo.nombre">
      <div class="form-group col-md-12">
        <img :src="archivo.archivo" class="img-fluid" />
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal ref="imagend" title="Agregar imagen">
      <div class="form-group col-md-12">
        <label>Nombre</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="archivo.nombre"
          class="form-control"
          :class="estatus($v.archivo.nombre)"
        />
        <div class="errores" v-if="!$v.archivo.nombre.required">Requerido</div>
      </div>
      <div class="col">
        <label for="descripcion">Foto: </label>
        <div style="max-width: 400px; max-height: 400px; overflow-y: auto">
          <archivo-imagen v-model="archivo.archivo"></archivo-imagen>
        </div>
        <div class="errores" v-if="!$v.archivo.archivo.required">Requerido</div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" variant="primary" @click="guardarImagen()"
          >Guardar</b-button
        >
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal ref="pdfd" title="Agregar PDF">
      <div class="form-group col-md-12">
        <label>Nombre</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="archivo.nombre"
          class="form-control"
          :class="estatus($v.archivo.nombre)"
        />
        <div class="errores" v-if="!$v.archivo.nombre.required">Requerido</div>
      </div>
      <div class="col">
        <label for="descripcion">Archivo: </label>
        <div style="max-width: 400px; max-height: 400px; overflow-y: auto">
          <archivo-pdf v-model="archivo.archivo"></archivo-pdf>
        </div>
        <div class="errores" v-if="!$v.archivo.archivo.required">Requerido</div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" variant="primary" @click="guardarPdf()"
          >Guardar</b-button
        >
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="guardando"
      title="Guardando"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { PDF_URL, API_URL } from "@/api";
import ArchivoImagen from "@/components/ArchivoImagen";
import ArchivoPdf from "@/components/ArchivoPdf";
import { required } from "vuelidate/lib/validators";
import BarraProgreso from "@/components/BarraProgreso";
//import { L } from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  name: "Reporte",
  components: {
    ArchivoImagen,
    ArchivoPdf,
    BarraProgreso,
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      ruta: API_URL,
      entradas: [],
      reporte_id: null,
      archivo: { nombre: null, archivo: null, tipo: null },
      entrada: { descripcion: null },

      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png', //'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
        //'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      punto: [51.504, -0.159],

      tiposolicitud: [],
      estados: [],
      municipios: [],
      localidades: [],
      prioridades: [],
      archivos: [],
      solicitud: {
        curp: null,
        telefono: null,
        id_tiposolicitud: null,
        id_prioridad: null,
        nombre: null,
        paterno: null,
        materno: null,
        email: null,
        calle: null,
        next: null,
        nint: null,
        cp: null,
        id_estado: 29,
        id_municipio: null,
        id_localidad: null,
        descripcion: null,
        foto: null,
      },
      errores: {},
      encontrado: false,
      guardando: false,
      avanceGuardando: 20,
      escolaridades: [],
      areas: [],
      instrucciones: [],
    };
  },
  validations() {
    return {
      entrada: {
        descripcion: { required },
        foto: {},
      },
      archivo: {
        nombre: { required },
        archivo: { required },
      },
    };
  },
  methods: {
    verUbicacion(entrada) {
      this.punto = [entrada.latitud, entrada.longitud];
      this.$refs.mapa.show();
    },
    mapaMostrado() {
      setTimeout(() => {
              //mapObject is a property that is part of leaflet
              this.$refs.mapaL.mapObject.invalidateSize(); 
            }, 100);
    },
    verArchivo(archivo) {
      this.archivo = archivo;
      this.$refs.archivo.show();
    },
    avance(progreso) {
      this.avanceGuardando = Math.round(
        (progreso.loaded * 100) / progreso.total
      );
    },
    agregarEntrada() {
      this.entrada = {
        descripcion: null,
        reporte_id: this.reporte_id,
        archivos: [],
      };
      this.$refs.entrada.show();
    },
    guardar: function () {
      this.$v.solicitud.$touch();
      if (!this.$v.solicitud.$invalid) {
        this.guardando = true;
        this.solicitud.archivos = this.archivos;

        api
          .guardarSolicitud(this.solicitud, this.avance)
          .then((r) => {
            this.$parent.aviso(r.data.mensaje);
            this.guardando = false;
            window.open(`${PDF_URL}/solicitud.php?id=${r.data.id}`, "_blank");
            this.$router.push({ path: "/" });
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
            this.guardando = false;
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    eliminarArchivo(pos) {
      this.archivos.splice(pos, 1);
    },
    abrirArchivo(archivo) {
      if (archivo.tipo == "img") {
        this.abrirImagen(archivo);
      } else {
        this.abrirPdf(archivo);
      }
    },
    abrirImagen(archivo) {
      this.archivo = archivo;
      this.$refs.verimagend.show();
    },
    abrirPdf(archivo) {
      const win = window.open("", "_blank");
      let html = "";

      html +=
        "<html><head><title>" +
        archivo.nombre +
        '</title></head><body style="margin:0!important">';
      html +=
        '<embed width="100%" height="100%" src="' +
        archivo.archivo +
        '" type="application/pdf" />';
      html += "</body></html>";

      setTimeout(() => {
        win.document.write(html);
      }, 0);
    },
    agregarPdf() {
      this.archivo = { nombre: null, archivo: null, tipo: "pdf" };
      this.$refs.pdfd.show();
    },
    agregarImagen() {
      this.archivo = { nombre: null, archivo: null, tipo: "img" };
      this.$refs.imagend.show();
    },
    guardarPdf() {
      this.$v.archivo.$touch();
      if (!this.$v.archivo.$invalid) {
        this.archivos.push(this.archivo);
        this.$refs.pdfd.hide();
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    guardarImagen() {
      this.$v.archivo.$touch();
      if (!this.$v.archivo.$invalid) {
        this.archivos.push(this.archivo);
        this.$refs.imagend.hide();
      } else {
        this.$parent.error("Información incompleta");
      }
    },

    estatus(validation) {
      if (validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    let id = this.$route.params.id;
    this.reporte_id = id;
    if (id > 0) {
      api.listarEntradas(id).then((respuesta) => {
        this.entradas = respuesta.data.entradas;
      });
    }
  },
};
</script>

