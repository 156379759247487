<template>
    <div class="mapa-robos">
      
      <iframe
        :src="mapUrl"
        width="100%"
        height="600"
        style="border:0;"
        allowfullscreen
        loading="lazy"
      ></iframe>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MapaRobos',
    data() {
      return {
        // Reemplaza la URL por la dirección de tu mapa
        mapUrl: 'https://tlax.vip/admin/mapas'
      };
    }
  };
  </script>
  
  <style scoped>
  .mapa-robos {
    padding: 15px;
  }
  
  iframe {
    border-radius: 8px; /* Opcional: añadir esquinas redondeadas */
  }
  </style>
  