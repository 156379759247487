<template>
  <div class="content">
    <div class="header-section">
      <div class="logo-section">
        <img src="@/assets/logo_tlah.png" class="logo">
      </div>
      <div class="report-count-section">
        <p>CONCENTRADO REPORTES</p>
        <h4>Distritos y Municipios</h4>
        <p>Reportes totales: 185</p>
      </div>

      <div class="status-indicators-horizontal">
        <div class="status-row">
          <div class="indicator">
            <span class="status-label">Pendiente</span>
            <span class="badge red">01</span>
          </div>
          <div class="indicator">
            <span class="status-label">Proceso</span>
            <span class="badge yellow">01</span>
          </div>
          <div class="indicator">
            <span class="status-label">Concluido</span>
            <span class="badge green">01</span>
          </div>
        </div>
        <div class="priority-row">
          <div class="indicator">
            <span class="status-label">Baja</span>
            <span class="badge light-green">01</span>
          </div>
          <div class="indicator">
            <span class="status-label">Media</span>
            <span class="badge orange">01</span>
          </div>
          <div class="indicator">
            <span class="status-label">Alta</span>
            <span class="badge red">01</span>
          </div>
        </div>
      </div>
  
    </div>

    <div class="action-section">
      <b-button class="btn-new-report" variant="primary" @click="agregar">
        <i class="fas fa-plus"></i> Escribir Nuevo Reporte
      </b-button>
      <div class="search-bar">
        <b-input-group>
          <b-input-group-prepend is-text>
            <i class="fas fa-search"></i>
          </b-input-group-prepend>
          <b-form-input placeholder="Buscar"></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="filters-section">
      <div class="filter-card">
        <span class="filter-label">MUNICIPIO</span>
        <i class="fas fa-filter filter-icon"></i>
        <b-form-select v-model="busqueda.municipio" :options="municipios" class="filter-input"></b-form-select>
      </div>
      <div class="filter-card">
        <span class="filter-label">ESTATUS</span>
        <i class="fas fa-filter filter-icon"></i>
        <b-form-select v-model="busqueda.estatus" :options="estatus" class="filter-input"></b-form-select>
      </div>
      <div class="filter-card">
        <span class="filter-label">GRAVEDAD</span>
        <i class="fas fa-filter filter-icon"></i>
        <b-form-select v-model="busqueda.gravedad" :options="gravedades" class="filter-input"></b-form-select>
      </div>
      <div class="filter-card">
        <span class="filter-label">FECHA</span>
        <i class="fas fa-calendar-alt filter-icon"></i>
        <b-form-datepicker v-model="busqueda.fecha" class="filter-input"></b-form-datepicker>
      </div>
    </div>

    <div class="table-section">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>ESTATUS</th>
              <th>TOTALES</th>
              <th>PRIORIDAD</th>
              <th>FECHA</th>
              <th>CREADO POR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reporte in listado" :key="reporte.id" :class="['priority-' + reporte.prioridad.toLowerCase()]">
              <td>{{ reporte.estatus }}</td>
              <td>{{ reporte.total }}</td>
              <td>{{ reporte.prioridad }}</td>
              <td>{{ reporte.fecha }}</td>
              <td>{{ reporte.creadoPor }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";
import { required, url } from "vuelidate/lib/validators";

export default {
  name: "MisReportes",
  data() {
    return {
      listado: [],
      busqueda: {
        municipio: '',
        estatus: '',
        gravedad: '',
        fecha: moment().format('YYYY-MM-DD')
      },
      municipios: ['Municipio 1', 'Municipio 2', 'Municipio 3'],
      estatus: ['Pendiente', 'En proceso', 'Concluido'],
      gravedades: ['Baja', 'Media', 'Alta']
    };
  },
  methods: {
    agregar() {
      console.log("Agregar nuevo reporte");
    },
    actualizaReportes() {
      api.buscarEntradas(this.busqueda).then(r => {
        if (r.data.respuesta) {
          this.listado = r.data.entradas;
        }
      });
    }
  },
  mounted() {
    this.actualizaReportes();
  }
};
</script>

<style scoped>
.content {
  max-width: 70%;
  margin: 0 auto;
  background-color: #17263d;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-left: 0px;
  position:fixed;
  
}
.logo{
  width: 280px;
  margin-top: -50px;
}
.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 2px solid #2c3e50;
}
.logo-section h2 {
  margin: 0;
  color: #0db8de;
}
.subtitle {
  margin: 0;
  font-size: 14px;
}
.report-count-section {
  text-align: center;
}
.status-indicators-horizontal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.status-row, .priority-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.status-label {
  font-size: 14px;
  font-weight: bold;
}
.badge {
  padding: 2px 5px;
  border-radius: 5px;
  color: #fff;
}
.red {
  background-color: #e57373;
}
.yellow {
  background-color: #fdd835;
}
.green {
  background-color: #81c784;
}
.light-green {
  background-color: #aed581;
}
.orange {
  background-color: #ffb74d;
}
.action-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.btn-new-report {
  background-color: #0db8de;
  color: white;
}
.filters-section {
  display: flex;
  gap: 25px;
  margin-bottom: 20px;
  margin-top:20px;
  align-items: center;
}
.filter-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;;
}
.filter-label {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
  background-color: transparent;
}
.filter-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #0db8de;
  font-size: 16px;
}
.filter-input {
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
}
.filter-input::placeholder {
  color: #ccc;
}
.table-section {
  margin-top: 20px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  color: transparent;
}
.table thead {
  background-color: #17263d;
}
.table tbody tr {
  border-bottom: 1px solid #2c3e50;
}
.priority-alta {
  background-color: #e57373;
}
.priority-media {
  background-color: #ffb74d;
}
.priority-baja {
  background-color: #81c784;
}
</style>

