<template>
  <div>
    <!-- Logo independiente en la esquina superior derecha -->
    <div class="header-logo">
      <router-link to="/home">
        <img src="@/assets/logo_gob.png" alt="Logo Gobierno" class="logo-superior-derecha" />
      </router-link>
    </div>
  <div class="container">
    <h4 class="titulo">Perfil de Usuario</h4>
    <p class="text-justify">
      <b>Actualiza tus datos personales</b>
    </p>
    <div class="form-row grupo"> 
      <!-- Campo de Nombre -->
      <div class="form-group col-md-12">
        <label>Nombre(s)</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="actual.nombre"
          class="form-control"
          :class="estatus($v.actual.nombre)"
        />
        <div class="errores" v-if="!$v.actual.nombre.required">Requerido</div>
      </div>
      
      <!-- Campo de Apellido Paterno -->
      <div class="form-group col-md-12">
        <label>Apellido Paterno</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="actual.paterno"
          class="form-control"
          :class="estatus($v.actual.paterno)"
        />
        <div class="errores" v-if="!$v.actual.paterno.required">Requerido</div>
      </div>
      
      <!-- Campo de Apellido Materno -->
      <div class="form-group col-md-12">
        <label>Apellido Materno</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="actual.materno"
          class="form-control"
          :class="estatus($v.actual.materno)"
        />
        <div class="errores" v-if="!$v.actual.materno.required">Requerido</div>
      </div>
      
      <!-- Campo de Clave de Operación -->
      <div class="form-group col-md-12">
        <label>Clave de operación</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="actual.clave"
          class="form-control"
        />
      </div>
      
      <!-- Campo de Usuario -->
      <div class="form-group col-md-12">
        <label>Usuario</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="actual.usuario"
          class="form-control"
          :class="estatus($v.actual.usuario)"
        />
        <div class="errores" v-if="!$v.actual.usuario.required">Requerido</div>
      </div>

      <!-- Opciones de Contraseña -->
      <div class="form-group col-md-12">
        <b-form-checkbox 
          v-model="cambiarContrasena"
        >
          Cambiar contraseña
        </b-form-checkbox>
      </div>
      <div class="form-group col-md-12" v-if="cambiarContrasena">
        <label>Contraseña</label>
        <input type="password"
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="actual.password"
          class="form-control"
          :class="estatus($v.actual.password)"
        />
        <div class="errores" v-if="!$v.actual.password.required">Requerido</div>
        <div class="errores" v-if="!$v.actual.password.minLength">Longitud mínima de seis caracteres</div>
      </div>
      <div class="form-group col-md-12" v-if="cambiarContrasena">
        <label>Confirmar contraseña</label>
        <input type="password"
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="actual.password2"
          class="form-control"
          :class="estatus($v.actual.password2)"
        />
        <div class="errores" v-if="!$v.actual.password2.required">Requerido</div>
        <div class="errores" v-if="!$v.actual.password2.iguales">Las contraseñas no coinciden</div>
      </div>
      
      <!-- Campo de Estatus Activo -->
      <div class="form-group col-md-12">
        <b-form-checkbox
          v-model="actual.activo"
        >
          Activo
        </b-form-checkbox>
      </div>
    </div>
    <b-button variant="primary" class="float-right" @click="guardar()">
      Actualizar
    </b-button>
  </div>
</div>
</template>


<script>
import api from "@/api";
import { required, requiredIf, sameAs, minLength } from "vuelidate/lib/validators";
import store from '../store';

export default {
  name: "Perfil",
  data() {
    return {
      actual: {
        nombre: "",
        paterno: "",
        materno: "",
        clave: "",
        usuario: "",
        activo: true,
        password: "",
        password2: ""
      },
      cambiarContrasena: false,
    };
  },
  validations() {
    return {
      actual: {
        nombre: { required },
        paterno: { required },
        materno: { required },
        usuario: { required },
        password: { 
          required: requiredIf(() => this.cambiarContrasena),
          minLength: minLength(6), 
          iguales: sameAs("actual.password2"),
        },
        password2: { 
          required: requiredIf(() => this.cambiarContrasena),
          iguales: sameAs("actual.password"),
        }
      },
    };
  },
  methods: {
    cargarPerfil() {
      // Usar los datos del usuario logueado desde `store`
      const usuarioLogueado = store.getters.usuario;
      
      if (usuarioLogueado) {
        this.actual = usuarioLogueado;
        this.cambiarContrasena = false;  // Desactiva el cambio de contraseña por defecto
      } else {
        console.error("Usuario logueado no encontrado en el store");
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        api
          .guardarUsuario(this.actual)
          .then((respuesta) => {
            console.log("Guardar respuesta:", respuesta.data); // Verificación de respuesta de guardar
            if (respuesta.data.success) {
              alert("Perfil actualizado con éxito");
            } else {
              console.error("Error al actualizar el perfil");
            }
          })
          .catch((error) => {
            console.error("Error al actualizar el perfil:", error);
          });
      } else {
        console.error("Información incompleta");
      }
    },
    estatus(validation) {
      return validation.$dirty && !validation.$error ? "dirty" : "error";
    },
  },
  mounted() {
    this.cargarPerfil();  // Llama a cargarPerfil al montar para cargar y mostrar los datos del usuario
  },
};
</script>
<style scuped>
.container {
  max-width: 700px; /* Limitar el ancho del formulario */
  background-color: transparent;
  padding: 40px;
  margin: 100px auto; /* Centrar en la pantalla */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Sombra alrededor */
  text-align: center;
  margin-top: -20px;
}
.titulo{
  font-size: 28px;
}

.grupo {
  display: flex;
  flex-direction: column; /* Apilar los elementos verticalmente */
  gap: 15px; /* Espacio entre los campos */
  background-color: transparent;
  border-color: transparent;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  text-align: center;
}

.form-group label {
  font-weight: bold;
  color: white;
  margin-bottom: 5px;
  text-align: left;
  width: 40%; /* Alineación de las etiquetas */
}

.form-control {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para los campos */
  transition: border-color 0.3s;
}

.form-control:focus {
  border-color: #007bff; /* Cambiar color de borde al enfocar */
}

.errores {
  color: #d9534f; /* Rojo para mensajes de error */
  font-size: 14px;
  margin-top: 5px;
}

.botones {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.boton-guardar {
  background-color: rgba(1, 187, 210);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.boton-guardar:hover {
  background-color: #0056b3; /* Color más oscuro en hover */
}

.header-logo {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 1050; /* Asegura que el logo esté por encima de otros elementos */
}

.logo-superior-derecha {
  width: 150px; /* Ajusta el tamaño del logo según sea necesario */
  height: auto;
}
</style>
