<template>
  <div>
    <h4>Listado de actores</h4>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Nombre</th>
          <th>Tipo actor</th>
          <th></th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td>{{ elemento.nombre }} {{ elemento.paterno }} {{ elemento.materno }}</td>
          <td>{{ elemento.tipoactor }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del actor</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Nombre(s)</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.nombre"
            class="form-control"
            :class="estatus($v.actual.nombre)"
          />
          <div class="errores" v-if="!$v.actual.nombre.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Apellido Paterno</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.paterno"
            class="form-control"
            :class="estatus($v.actual.paterno)"
          />
          <div class="errores" v-if="!$v.actual.paterno.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Apellido Materno</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.materno"
            class="form-control"
            :class="estatus($v.actual.materno)"
          />
          <div class="errores" v-if="!$v.actual.materno.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Tipo actor</label>
          <select
            class="form-control"
            style="max-width: 500px"
            v-model="actual.tipoactor_id"
            :class="estatus($v.actual.tipoactor_id)"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="tipoactor in tipoactores"
              :key="tipoactor.id"
              v-bind:value="tipoactor.id"
            >
              {{ tipoactor.tipoactor }}
            </option>
          </select>
          <div class="errores" v-if="!$v.actual.tipoactor_id.required">Requerido</div>
        </div>        
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required, } from "vuelidate/lib/validators";

export default {
  name: "Actores",
  data() {
    return {
      titulo: "Agregar usuario",
      listado: [],
      actual: {tipoactor_id: null},
      tipoactores: [],
    };
  },
  validations() {
    return {
      actual: {
        nombre: {
          required,
        },
        paterno: {
          required,
        },
        materno: {
          required,
        },
        tipoactor_id: {
          required,
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo= "Agregar actor",
      this.actual = {tipoactor_id: null, };
      this.$refs.detalle.show();
    },
    editar (elemento) {
      this.titulo= "Editar actor",
      this.actual = Object.assign({}, elemento);
      this.$refs.detalle.show();
    },
    eliminar (elemento) {
      if (confirm("Confirma eliminar el actor: "+elemento.nombre)) {
        api.eliminarActor(elemento.id)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaActores();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {this.$parent.aviso("No se pudo eliminar: "+error);});
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        api
          .guardarActor(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaActores();
            } else {
              this.$parent.error("Error al guardar la información "+r.mensaje);
            }
          })
          .catch((error) => {
              this.$parent.error("Error al guardar la información "+error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizaActores() {
      api.listarActores().then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        } else {
          this.listado = [];
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaActores();
    api
      .listarTipoActores()
      .then((respuesta) => (this.tipoactores = respuesta.data));
  },
};
</script>

