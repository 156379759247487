<template>
  <div>
    <h4>Seguimiento a audiencias</h4>
    <div class="grupo">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Mes:</label>
          <input class="form-control" type="month" v-model="busqueda.mes" />
        </div>
      </div>
      <div class="text-right">
        <b-button @click="actualizaAudiencias">Buscar</b-button>
      </div>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>No</th>
          <th>Fecha y hora</th>
          <th>Asunto</th>
          <th>Estatus</th>
          <th>Solicita</th>
          <th>Acciones</th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td>{{ elemento.id }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              {{ elemento.fecha }}</a
            >
          </td>
          <td>{{ elemento.asunto }}</td>
          <td>{{ elemento.estatus_audiencia.estatus_audiencia }}</td>
          <td>
            {{
              elemento.personas.length > 0
                ? elemento.personas[0].nombre +
                  " " +
                  elemento.personas[0].paterno +
                  " " +
                  elemento.personas[0].materno
                : ""
            }}
          </td>
          <td>
            <a
              v-if="elemento.estatus_audiencia.id > 0"
              href="javascript:;"
              @click="editar(elemento)"
            >
              <font-awesome-icon size="lg" icon="plus-square" />
            </a>&nbsp;
            <a
              href="javascript:;"
              @click="detalle(elemento)"
            >
              <font-awesome-icon size="lg" icon="info-circle" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal size="lg" ref="detalle" title="Agregar seguimiento a la audiencia">
      <div class="form-row grupo">
        <div class="form-group mb-0 col-md-12">
          <label>No: <b>{{actual.id}}</b></label>
        </div>
        <div class="form-group mb-0 col-md-12">
          <label>Fecha y hora: <b>{{actual.fecha}}</b></label>
        </div>
        <div class="form-group mb-0 col-md-12">
          <label>Asunto original de la audiencia</label
          >
          <b-textarea
            rows="4"
            v-model.trim="actual.asunto"
            class="form-control"
            :class="estatus($v.actual.asunto)"
          />          
            <div class="errores" v-if="!$v.actual.asunto.required">
              Requerido
            </div>
        </div>
      </div>
      <div class="form-row grupo">
        <div class="form-group mb-0  col-md-12">
          <label>Estatus</label>
            <select
              v-model="seguimiento.estatus_audiencia_id"
              :class="estatus($v.seguimiento.estatus_audiencia_id)"
              class="form-control"
            >
              <option
                v-for="e in listaestatus"
                :key="e.id"
                v-bind:value="e.id"
              >
                {{ e.estatus_audiencia }}
              </option>
            </select>
            <div class="errores" v-if="!$v.seguimiento.estatus_audiencia_id.required">
              Requerido
            </div>
        </div>        
        <div class="form-group mb-0 col-md-12">
          <label>Seguimiento</label
          >
          <b-textarea
            rows="4"
            v-model.trim="seguimiento.seguimiento"
            class="form-control"
            :class="estatus($v.seguimiento.seguimiento)"
          />          
            <div class="errores" v-if="!$v.seguimiento.seguimiento.required">
              Requerido
            </div>
        </div>
        <div class="form-group col-md-6">
          <label for="descripcion">Documento</label>
          <div style="overflow-y: auto">
            <archivo-pdf
              ref="pdf"
              v-model="seguimiento.documento"
            ></archivo-pdf>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="descripcion">Minuta</label>
          <div style="overflow-y: auto">
            <archivo-pdf
              ref="pdf"
              v-model="seguimiento.minuta"
            ></archivo-pdf>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal size="lg" ref="seguimiento" title="Detalle del seguimiento a la audiencia">
      <div class="form-row grupo">
        <div class="form-group mb-0 col-md-12">
          <label>No: <b>{{actual.id}}</b></label>
        </div>
        <div class="form-group mb-0 col-md-12">
          <label>Fecha y hora: <b>{{actual.fecha}}</b></label>
        </div>
        <div class="form-group mb-0 col-md-12">
          <label>Asunto</label>
          <div style="height: 100px; overflow-y: auto; border: 1px solid lightgray;">
            {{actual.asunto}}
          </div>
        </div>
      </div>
      <div class="form-row grupo">
        <h4>Comisión</h4>
        <table class="table">
          <thead>
            <tr>
              <th>Puesto</th>
              <th>Nombre</th>
              <th>Tel</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="per in actual.personas" :key="per.id" >
              <td>{{per.puesto}}</td>
              <td>{{per.nombre}} {{per.paterno}} {{per.materno}}</td>
              <td>{{per.tel}}</td>
            </tr>
          </tbody>
        </table>
        
      </div>
      <div class="form-row grupo">
        <h4>Historial</h4>
        <table class="table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Estatus</th>
              <th>Seguimiento</th>
              <th>Archivo</th>
              <th>Minuta</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="seg in listadoseguimiento" :key="seg.id" >
              <td>{{seg.fecha}}</td>
              <td>{{seg.seguimiento}}</td>
              <td>{{seg.estatus_audiencia.estatus_audiencia}}</td>
              <td>
                <a :href="`${ruta}/archivo/${seg.documento.ruta}`" v-if="seg.documento != null" target="_blank">
                  <font-awesome-icon size="lg" icon="file-pdf" /> Ver archivo
                </a>                
              </td>
              <td>
                <a :href="`${ruta}/archivo/${seg.minuta.ruta}`" v-if="seg.minuta != null" target="_blank">
                  <font-awesome-icon size="lg" icon="file-pdf" /> Ver minuta
                </a>                
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    
    <b-modal
      v-model="guardando"
      title="Guardando"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import ArchivoPdf from "@/components/ArchivoPdf";
import BarraProgreso from "@/components/BarraProgreso";

export default {
  name: "Audiencias",
  components: { ArchivoPdf, BarraProgreso },
  data() {
    return {
      ruta: API_URL,
      busqueda: { mes: moment(Date.now()).format("YYYY-MM") },
      listado: [],
      listaestatus: [],
      listadoseguimiento: [],
      indicePersona: 0,
      localidades: [],
      municipios: [],
      buscar: {},
      guardando: false,
      avanceGuardando: 0,
      seguimiento: {},

      posibles: [],
      actual: {
        nombre: null,
        descripcion: null,
        activo: true,
      },
    };
  },
  validations() {
    return {
      actual: {
        asunto: {
          required,
        },
      },
      seguimiento: {
        seguimiento: { required},
        estatus_audiencia_id: {required},
      },      
    };
  },
  methods: {
    editar(elemento) {
      this.posibles = [];
      this.seguimiento = {};

      let ahora = Date.parse(elemento.fecha);
      this.actual = Object.assign({}, elemento);
      (this.actual.fecha = moment(ahora).format("YYYY-MM-DDTHH:mm:ss")),
        this.$refs.detalle.show();
    },
    detalle(elemento) {
      this.actualizaSeguimientoAudiencia(elemento.id);

      let ahora = Date.parse(elemento.fecha);
      this.actual = Object.assign({}, elemento);
      this.actual.fecha = moment(ahora).format("YYYY-MM-DDTHH:mm:ss");
      this.$refs.seguimiento.show();
    },
    avance(progreso) {
      this.avanceGuardando = Math.round((progreso.loaded * 100) / progreso.total);
    },
    guardar() {
      this.$v.seguimiento.$touch();
      this.$v.actual.$touch();
      if (!this.$v.seguimiento.$invalid && !this.$v.actual.$invalid) {
        this.guardando = true;
        this.seguimiento.audiencia_id = this.actual.id;
        this.seguimiento.asunto = this.actual.asunto;
        api
          .guardarSeguimientoAudiencia(this.seguimiento, this.avance)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaAudiencias();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizaEstatus() {
      api.listarEstatusAudiencia().then((respuesta) => {
        this.listaestatus = respuesta.data;
      });
    },
    actualizaAudiencias() {
      api.listarAudiencias(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        }
      });
    },
    actualizaSeguimientoAudiencia(id) {
      api.listarSeguimientoAudiencia(id).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoseguimiento = r.data;
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaEstatus();
    this.actualizaAudiencias();
  },
};
</script>

