<template>
    <div class="sistema-escucha">
      <header class="header">
        <router-link to="/home">
          <img src="@/assets/logo_gob.png" alt="Logo Gobierno" class="logo">
        </router-link>
      <nav>
        <router-link to="/monitoreo/opiniones">Opiniones y Percepciones Públicas</router-link>
        <router-link to="/monitoreo/incidentes">Incidentes Sociopolíticos</router-link>
        <router-link to="/monitoreo/riesgos">Datos de Riesgo y Conflictividad</router-link>
        <router-link to="/monitoreo/eventos">Eventos Importantes</router-link>
      </nav>
    </header>
    <h2>Sistema de Escucha Social y Alerta en Medios Digitales</h2>
    <div class="icon">
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA4pJREFUaEPtmu1RHDEMhq1OQidQSUIlgUoClUAnoRPlxNiMY/Rte28Obv8kw669eizplexbKN/sgm/GW67AX93jVw/v9DAi/iil/Cql/D79+1ZKeS6lPAEA/f+Q6xAPD6Aj2KHgW4EN0LOAbwEOgh4KvhR4EvQQ8CXACdDHSkfiZV1Lc3wKOAMKAA+NMDh+CXgKOGgo8T32oKNLg/NNgYeAg4aZoOcAdwHvBj0S3ARGROqM/ljKUu+roeuc4+Ox4EK/h7qWOjSxCoyIt6WUF4ehS0EnPX4HAK+SzRYwKapWOraCJsFVm7LAh4IGwZcD3wPAkyPMtz8i6MtlApNgWdtGRORSbjnw9nBGRKoKJJgkQOJe+UsAV1gqhXQRrAg9PNtSSE05S7S4GkwnFPc7ElQAEKF3AHN1+BUA7lYDC8a317BphIjUI5CN/TVVh+kM6u8w4RsA3KwENmDFiEJEso1s7K8bLe89rSWOcHDq3yTg2g6SIS5xy8LS+/GkWhHbzNayTuoOmw5WDcV2cxKW0xcz3TwephLRVFNUQgbWyj9u3jbGFEah6TDHeYBdKynURBZ6xrNddHCRZ3aBHmC3cHmgV8BK+XvaHqqC5crhRB5rOyyqqaOqusO48y67R9fEtI01PVyBuXzTyoW1rRzF1cy9foAQJa45vMBcA6LWYyO8e/tdhg7An8pRbUHFjX/Iw0pYqyLhgM7AstHjCWd3Dldg7kVm16VAh2EVsXLP5Qrp+iJOremWWQoYaLeBQyhLB4qmOodDWglr08tDhKRg6xxc7xyaz+1hw8vevvlWO1EcpXvwrqT86u5onDMErJQoqq8U2qZKalDSPeW4OOTdkGh1RV/KZVdoJ4G5UKap3LmbyuEOWgqv8IpbCyBs8mmYK42mQ7rL5XbQNs6ZMoQDV37mMbeB0kKGc9gR2unVd4oUPRYSqn7eNHD1tPZDW9rTxo7KrPtamkwBD/WVe08YOnOYZ+nAMg87RIweoZ9lCFz9+KyemEi6QPOk83YHcP+FHbfg6m+3jk3GEthUHVaaAwuahv7XoDi8+h4hKw/+p3N4XACHt9pHKTTU+mwprAFWPi8H7tSbYKTjHMuuba3qFuCuOWlfzlqA/f1l+cq9dBuwU8F7m1wfpURW7izADm8fAtrgt3u4X+XuM6Sf9e/mZ0azHh3HHwq82vjMfFfgzKpd0pirhy/JWxlb/wE6g0xbHnlMiQAAAABJRU5ErkJggg==" class="icono"/>
    </div>
      <router-view />
    </div>
  </template>
  
  <script>
  export default {
    name: 'SistemaEscucha'
  }
  </script>
  
  <style scoped>
  /* Estilos específicos para el componente SistemaEscucha */
  .sistema-escucha {
    padding: 0px;
  }
  .header {
    display: flex;
    align-items: center;
    background-color: #044e988d;
    padding: 20px;
    color: white;
  }
  
  .logo {
    width: 150px;
    margin-right: 20px;
    cursor: pointer;
  }
  
  nav {
    display: flex;
    gap: 15px;
  }
  
  nav a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  
  nav a:hover {
    text-decoration: underline;
  }

  h2{
    color:white;
  }
 
  .icon{
    padding: 50px ;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  </style>
  