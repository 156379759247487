<template>
  <div>
    <h4>Reportes de actividades :: Digitales</h4>
    <div class="grupo">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Usuario:</label>
          <select class="form-control" v-model="busqueda.usuario_id">
            <option value="" selected>Todos</option>
            <option
              v-for="usuario in usuarios"
              :key="usuario.id"
              v-bind:value="usuario.id"
            >
              {{ usuario.nombre }} {{ usuario.paterno }} {{ usuario.materno }} 
            </option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label>Fecha:</label>
          <b-form-datepicker
            v-model="busqueda.fecha"
            placeholder="Todas"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="text-right">
        <b-button @click="buscar">Buscar</b-button>
      </div>
    </div>
    <div style="overflow-y: auto">
      <div class="grupo" v-for="entrada in entradas" :key="entrada.id">
        <table>
          <tr>
            <td>Código:</td>
            <td>
              <b
                ><span
                  v-b-tooltip.hover
                  v-bind:title="
                    entrada.usuario.nombre +
                    ' ' +
                    entrada.usuario.paterno +
                    ' ' +
                    entrada.usuario.materno
                  "
                  >{{ entrada.usuario.clave || "Sin código" }}</span
                ></b
              >
            </td>
          </tr>
          <tr>
            <td>Asunto:</td>
            <td>
              <b>{{ entrada.titulo || "Sin asunto" }}</b>
            </td>
          </tr>
          <tr>
            <td>Prioridad:</td>
            <td>
              <b>{{ entrada.prioridad }}</b>
            </td>
          </tr>
          <tr>
            <td>Tipo de información:</td>
            <td>
              <b>{{
                entrada.tipoinformacion != null
                  ? entrada.tipoinformacion.tipoinformacion
                  : "Sin tipo de información"
              }}</b>
            </td>
          </tr>
          <tr>
            <td>Tipo de conflicto:</td>
            <td>
              <b>{{
                entrada.tipoconflicto != null
                  ? entrada.tipoconflicto.tipoconflicto
                  : "Sin tipo de conflicto"
              }}</b>
            </td>
          </tr>
          <tr>
            <td>URL:</td>
            <td>
              <b><a :href="entrada.url" target="_blank" >{{ entrada.url || "Sin url" }}</a></b>
            </td>
          </tr>
          <tr>
            <td>Fecha:</td>
            <td>
              <b>{{ entrada.fecha }}</b>
            </td>
          </tr>
        </table>
        <div class="border border-warning" style="min-height: 80px">
          {{ entrada.descripcion }}
        </div>
        <div style="display: flex">
          <div
            class="text-center"
            v-for="m in entrada.multimedios"
            :key="m.id"
            style="width: 50px; height: 40px; padding: 2px; border: 1px solid lightgray;"
          >
            <a v-if="['jpeg', 'jpg', 'png', 'gif'].includes(m.tipo)" href="javascript:;" @click="verArchivo(m)">
              <img
                :src="`${ruta}/archivo/${m.ruta}`"
                width="100%"
                style="object-fit: cover"
              />
            </a>
            <a v-if="m.tipo == 'pdf'" :href="`${ruta}/archivo/${m.ruta}`" target="_blank">
              <font-awesome-icon size="24" icon="file-pdf" />            
            </a>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="lg" ref="mapa" title="Ubicación" @show="mapaMostrado">
      <div class="col">
        <l-map ref="mapaL" style="height: 400px" :zoom="zoom" :center="punto">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="punto"></l-marker>
        </l-map>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal size="lg" ref="archivo" :title="archivo.nombre">
      <div class="col">
        <img :src="`${ruta}/archivo/${archivo.ruta}`" class="img-fluid" />
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal ref="verimagend" :title="archivo.nombre">
      <div class="form-group col-md-12">
        <img :src="archivo.archivo" class="img-fluid" />
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
     
import api from "@/api";
import { API_URL } from "@/api";
import moment from 'moment';
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";


export default {
  name: "Entradas",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      ruta: API_URL,
      entradas: [],
      busqueda: { usuario_id: '', reporte_id: '', fecha: moment(Date.now()).format('YYYY-MM-DD'), 'tiporeporte': 'internet' },
      archivo: { nombre: null, archivo: null, tipo: null },
      entrada: { descripcion: null },
      usuarios: [],

      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png", //'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
      zoom: 10,
      punto: [51.504, -0.159],
    };
  },
  methods: {
    verUbicacion(entrada) {
      this.punto = [entrada.latitud, entrada.longitud];
      this.$refs.mapa.show();
    },
    mapaMostrado() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        this.$refs.mapaL.mapObject.invalidateSize();
      }, 100);
    },
    verArchivo(archivo) {
      this.archivo = archivo;
      this.$refs.archivo.show();
    },

    abrirArchivo(archivo) {
      if (archivo.tipo == "img") {
        this.abrirImagen(archivo);
      } else {
        this.abrirPdf(archivo);
      }
    },
    buscar() {
      api.buscarEntradas(this.busqueda).then((respuesta) => {
        this.entradas = respuesta.data.entradas;
      });
    },
    abrirImagen(archivo) {
      this.archivo = archivo;
      this.$refs.verimagend.show();
    },
    abrirPdf(archivo) {
      const win = window.open("", "_blank");
      let html = "";

      html +=
        "<html><head><title>" +
        archivo.nombre +
        '</title></head><body style="margin:0!important">';
      html +=
        '<embed width="100%" height="100%" src="' +
        archivo.archivo +
        '" type="application/pdf" />';
      html += "</body></html>";

      setTimeout(() => {
        win.document.write(html);
      }, 0);
    },
  },
  mounted() {
    let id = this.$route.params.id;
    this.reporte_id = id;
    if (id > 0) {
      api.listarEntradas(id).then((respuesta) => {
        this.entradas = respuesta.data.entradas;
      });
    }
    api
      .listarUsuarios()
      .then((respuesta) => {
        this.usuarios = respuesta.data;
      });
  },
};
</script>

