<template>
  <div>
    <h4>Listado de usuarios</h4>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Nombre</th>
          <th>Clave</th>
          <th>Estatus</th>
          <th>Rol</th>
          <th></th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td>{{ elemento.nombre }} {{ elemento.paterno }} {{ elemento.materno }}</td>
          <td>{{ elemento.clave }}</td>
          <td>{{ elemento.activo ? 'Activo' : 'Inactivo' }}</td>
          <td>{{ elemento.rol }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del usuario</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Nombre(s)</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.nombre"
            class="form-control"
            :class="estatus($v.actual.nombre)"
          />
          <div class="errores" v-if="!$v.actual.nombre.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Apellido Paterno</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.paterno"
            class="form-control"
            :class="estatus($v.actual.paterno)"
          />
          <div class="errores" v-if="!$v.actual.paterno.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Apellido Materno</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.materno"
            class="form-control"
            :class="estatus($v.actual.materno)"
          />
          <div class="errores" v-if="!$v.actual.materno.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Clave de operación</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.clave"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-12">
          <label>Rol</label>
          <select
            class="form-control"
            style="max-width: 500px"
            v-model="actual.rol_id"
            :class="estatus($v.actual.rol_id)"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="rol in roles"
              :key="rol.id"
              v-bind:value="rol.id"
            >
              {{ rol.rol }}
            </option>
          </select>
          <div class="errores" v-if="!$v.actual.rol_id.required">Requerido</div>
        </div>        
        <div class="form-group col-md-12">
          <label>Usuario</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.usuario"
            class="form-control"
            :class="estatus($v.actual.usuario)"
          />
          <div class="errores" v-if="!$v.actual.usuario.required">Requerido</div>
        </div>
        <div class="form-group col-md-12" v-if="actual.id > 0">
          <b-form-checkbox 
            v-model="cambiarContrasena"
          >
            Cambiar contraseña
          </b-form-checkbox>
          </div>
        <div class="form-group col-md-12" v-if="cambiarContrasena">
          <label>Contraseña</label>
          <input type="password"
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.password"
            class="form-control"
            :class="estatus($v.actual.password)"
          />
          <div class="errores" v-if="!$v.actual.password.required">Requerido</div>
          <div class="errores" v-if="!$v.actual.password.minLength">Longitud mínima de seis caracteres</div>
          <div class="errores" v-if="!$v.actual.password.iguales">Las contraseñas no coinciden</div>
        </div>
        <div class="form-group col-md-12" v-if="cambiarContrasena">
          <label>Confirmar contraseña</label>
          <input type="password"
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.password2"
            class="form-control"
            :class="estatus($v.actual.password2)"
          />
          <div class="errores" v-if="!$v.actual.password2.required">Requerido</div>
          <div class="errores" v-if="!$v.actual.password2.iguales">Las contraseñas no coinciden</div>
        </div>
        <div class="form-group col-md-12">
          <b-form-checkbox
            v-model="actual.activo"
          >
            Activo
          </b-form-checkbox>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required, requiredIf, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "Usuarios",
  data() {
    return {
      titulo: "Agregar usuario",
      listado: [],
      cambiarContrasena: false,
      actual: {rol_id: null, usuario: null, password: null, password2: null},
      roles: [],
      tiposusuario: [],
    };
  },
  validations() {
    return {
      actual: {
        nombre: {
          required,
        },
        paterno: {
          required,
        },
        materno: {
          required,
        },
        usuario: {
          required,
        },
        password: { 
          required: requiredIf(function (s) { 
            return this.cambiarContrasena && !("id" in s);
          }),
          minLength: minLength(6), 
          iguales: this.cambiarContrasena && sameAs('password2'),
        },
        password2: { 
          required: requiredIf(function (s) { 
            return this.cambiarContrasena && !("id" in s);
          }),
          minLength: minLength(6), 
          iguales: this.cambiarContrasena && sameAs('password'),
        },
        rol_id: {
          required,
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo= "Agregar usuario",
      this.cambiarContrasena = true;
      this.actual = {rol_id: null, usuario: null, password: '', password2: ''};
      this.$refs.detalle.show();
    },
    editar (elemento) {
      this.titulo= "Editar usuario",
      this.cambiarContrasena = false;
      this.actual = Object.assign({password: '', password2: ''}, elemento);
      this.$refs.detalle.show();
    },
    eliminar (elemento) {
      if (confirm("Confirma eliminar el usuario: "+elemento.nombre)) {
        api.eliminarUsuario(elemento.id)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaUsuarios();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {this.$parent.aviso("No se pudo eliminar: "+error);});
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        api
          .guardarUsuario(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaUsuarios();
            } else {
              this.$parent.error("Error al guardar la información "+r.mensaje);
            }
          })
          .catch((error) => {
              this.$parent.error("Error al guardar la información "+error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizaUsuarios() {
      api.listarUsuarios().then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        } else {
          this.listado = [];
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaUsuarios();
    api
      .listarRoles()
      .then((respuesta) => (this.roles = respuesta.data));
  },
};
</script>

