<template>
  <div>
    <h4>Reporte de actividades :: Por reporte</h4>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Nombre</th>
          <th>Tipo de evento</th>
          <th>Estatus</th>
          <th>Ultima Actualización</th>
          <th></th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td><a href="javascript:;" @click="abrir(elemento)"> {{ elemento.nombre }}</a></td>
          <td>{{ elemento.tipoevento }}</td>
          <td>{{ elemento.activo ? 'Activo' : 'Inactivo' }}</td>
          <td>{{ elemento.modificado }}</td>
          <td>
            <a href="javascript:;" @click="abrir(elemento)">
              <font-awesome-icon size="lg" icon="folder-open" />
            </a>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del reporte</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Nombre</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.nombre"
            class="form-control"
            :class="estatus($v.actual.nombre)"
          />
          <div class="errores" v-if="!$v.actual.nombre.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Descripción</label>
          <b-textarea
            v-model.trim="actual.descripcion"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-12">
          <label>Tipo de evento:</label>
          <select
            class="form-control"
            style="max-width: 500px"
            v-model="actual.tipoevento_id"
            :class="estatus($v.actual.tipoevento_id)"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="te in tipoeventos"
              :key="te.id"
              v-bind:value="te.id"
            >
              {{ te.tipoevento }}
            </option>
          </select>
          <div class="errores" v-if="!$v.actual.tipoevento_id.required">Requerido</div>
        </div>        
        <div class="form-group col-md-12">
          <b-form-checkbox
            v-model="actual.activo"
          >
            Activo
          </b-form-checkbox>
        </div>
        <div class="form-group col-md-12">
          <label>Asignado a: <a href="javascript:;" @click="seleccionarUsuario()"><font-awesome-icon size="lg" icon="plus-square" /> Agregar</a></label>
          <div class="col form-control" style="height: 80px; overflow:auto;">
            <div v-for="usuario in usuariosr" :key="usuario.id">{{usuario.nombre}} {{usuario.paterno}} {{usuario.materno}}</div>
          </div>
        </div>        
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal ref="usuario" title="Seleccionar usuario">
      <p class="text-justify">
        <b>Seleccione el usuario</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Usuario:</label>
          <select
            class="form-control"
            style="max-width: 500px"
            v-model="usuario"
            :class="estatus(usuario.id)"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="usuario in usuarios"
              :key="usuario.id"
              v-bind:value="usuario"
            >
              {{ usuario.nombre }} {{ usuario.paterno }} {{ usuario.materno }}
            </option>
          </select>
          <div class="errores" v-if="!$v.usuario.id.required">Requerido</div>
        </div>        
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="agregarUsuario()">
          Agregar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Reportes",
  data() {
    return {
      titulo: "Agregar reporte",
      listado: [],
      usuariosr: [],
      usuarios: [],
      usuario: {id:0},
      actual: {tipoevento_id: null, nombre: null, descripcion: null, activo: true},
      tipoeventos: [],
    };
  },
  validations() {
    return {
      usuario: {
        id: {
          required,
        },
      },
      actual: {
        nombre: {
          required,
        },
        tipoevento_id: {
          required,
        },
      },
    };
  },
  methods: {
    seleccionarUsuario() {
      this.usuario = {id:0};
      this.$refs.usuario.show();
    },
    agregarUsuario() {
      this.usuariosr.push(this.usuario);
      this.$refs.usuario.hide();
    },

    abrir(elemento) {
      this.$router.push(`/reporte/${elemento.id}`);
    },

    agregar() {
      this.titulo= "Agregar reporte",
      this.usuariosr = [];
      this.actual = {tipoevento_id: null, nombre: null, descripcion: null, activo: true};
      this.$refs.detalle.show();
    },
    editar (elemento) {
      this.titulo= "Editar reporte",
      this.usuariosr = elemento.usuarios;
      this.actual = Object.assign({}, elemento);
      this.$refs.detalle.show();
    },
    eliminar (elemento) {
      if (confirm("Confirma eliminar el reporte: "+elemento.nombre)) {
        api.eliminarReporte(elemento.id)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaReportes();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {this.$parent.aviso("No se pudo eliminar: "+error);});
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        let usus = [];
        this.usuariosr.forEach(usu => {
          usus.push(usu.id);
        });
        this.actual.usuarios = usus;
        api
          .guardarReporte(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaReportes();
            } else {
              this.$parent.error("Error al guardar la información "+r.mensaje);
            }
          })
          .catch((error) => {
              this.$parent.error("Error al guardar la información "+error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizaReportes() {
      api.listarReportes().then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaReportes();
    api
      .listarTipoEventos()
      .then((respuesta) => {
        this.tipoeventos = respuesta.data;
      });
    api
      .listarUsuarios()
      .then((respuesta) => {
        this.usuarios = respuesta.data;
      });
  },
};
</script>

