<template>
  <div class="directorio-container">
    <h4 class="title">Directorio</h4>
    <ul class="tree-list">
      <tree-item
        class="tree-item"
        :item="organigramas"
        @add-actor="addActor"
        @edit-actor="editActor"
        @del-actor="delActor"
        @add-nodo="addNodo"
        @edit-files="editFiles"
        @edit-nodo="editNodo"
        @del-nodo="delNodo"
        @exp-nodo="expNodo"
      ></tree-item>
    </ul>

    <!-- Modal para Grupo -->
    <b-modal
      ref="organigrama"
      v-bind:title="organigrama.id ? 'Editar Grupo' : 'Agregar Grupo'"
      centered
      hide-backdrop
      class="custom-modal"
    >
      <div class="form-group">
        <label>Nombre:</label>
        <input
          type="text"
          class="form-control"
          v-model="organigrama.nombre"
          :class="estatus($v.organigrama.nombre)"
          placeholder="Ingrese el nombre del grupo"
        />
        <small class="text-danger" v-if="!$v.organigrama.nombre.required">
          Requerido
        </small>
      </div>
      <div class="form-group">
        <label>Descripción:</label>
        <textarea
          class="form-control"
          v-model="organigrama.descripcion"
          placeholder="Ingrese la descripción"
        ></textarea>
      </div>

      <template #modal-footer="{ hide }">
        <button @click="guardar" class="btn btn-success">Guardar</button>
        <b-button @click="hide()" class="btn btn-outline-secondary">Cancelar</b-button>
      </template>
    </b-modal>

    <!-- Modal para Actor -->
    <b-modal
      ref="actor"
      size="lg"
      v-bind:title="actor.id ? 'Editar Actor' : 'Agregar Actor'"
      centered
      hide-backdrop
      class="custom-modal"
    >
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Foto:</label>
          <archivo-imagen v-model="actor.foto" class="image-uploader"></archivo-imagen>
        </div>
        <div class="form-group col-md-6">
          <label>Ficha:</label>
          <archivo-pdf v-model="actor.ficha" class="pdf-uploader"></archivo-pdf>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Nombre:</label>
          <input
            type="text"
            class="form-control"
            v-model="actor.nombre"
            :class="estatus($v.actor.nombre)"
            placeholder="Nombre del actor"
          />
          <small class="text-danger" v-if="!$v.actor.nombre.required">Requerido</small>
        </div>
        <div class="form-group col-md-6">
          <label>Apellido Paterno:</label>
          <input
            type="text"
            class="form-control"
            v-model="actor.paterno"
            :class="estatus($v.actor.paterno)"
            placeholder="Apellido paterno"
          />
          <small class="text-danger" v-if="!$v.actor.paterno.required">Requerido</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Apellido Materno:</label>
          <input
            type="text"
            class="form-control"
            v-model="actor.materno"
            :class="estatus($v.actor.materno)"
            placeholder="Apellido materno"
          />
          <small class="text-danger" v-if="!$v.actor.materno.required">Requerido</small>
        </div>
        <div class="form-group col-md-6">
          <label>Correo Electrónico:</label>
          <input
            type="email"
            class="form-control"
            v-model="actor.email"
            :class="estatus($v.actor.email)"
            placeholder="Correo electrónico"
          />
          <small class="text-danger" v-if="!$v.actor.email.email">Correo inválido</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Teléfono:</label>
          <input
            type="text"
            class="form-control"
            v-model="actor.tel"
            :class="estatus($v.actor.tel)"
            placeholder="Teléfono"
          />
        </div>
        <div class="form-group col-md-6">
          <label>Celular:</label>
          <input
            type="text"
            class="form-control"
            v-model="actor.cel"
            :class="estatus($v.actor.cel)"
            placeholder="Celular"
          />
        </div>
      </div>
      <div class="form-group">
        <label>Información Adicional:</label>
        <textarea
          class="form-control"
          v-model="actor.puesto_adicional"
          placeholder="Ingrese información adicional"
        ></textarea>
      </div>

      <template #modal-footer="{ hide }">
        <button @click="guardarActor" class="btn btn-success">Guardar</button>
        <b-button @click="hide()" class="btn btn-outline-secondary">Cancelar</b-button>
      </template>
    </b-modal>
  </div>
</template>
   
<script>
import TreeItem from "@/components/TreeItem.vue";
import { API_URL } from "@/api";
import ArchivoImagen from "@/components/ArchivoImagen.vue";
import ArchivoPdf from "@/components/ArchivoPdf.vue";
import api from "@/api";
import { required, email } from "vuelidate/lib/validators";

// define the tree-item component
export default {
  components: { TreeItem, ArchivoImagen, ArchivoPdf },
  data() {
    return {
      ruta: API_URL,
      stipo: 1,
      id: null,
      idOrganigrama: null,
      organigrama: {},
      actor: {},
      padre: {},
      organigramas: {},
      municipios: [],
      localidades: [],
      partidos: [],
      guardando: false,
    };
  },
  validations() {
    return {
      organigrama: {
        nombre: {
          required,
        },
      },
      actor: {
        puesto: {
          required: false,
        },
        puesto_adicional: {},
        email: {email, required: false}, 
        email2: {email, required: false}, 
        cel: {required: false},
        cel2: {required: false},
        tel: {required: false},
        tel2: {required: false},
        ext: {required: false},
        ext2: {required: false},
        partidopolitico_id: {required: false},
        nombre: {
          required,
        },
        paterno: {
          required,
        },
        materno: {
          required,
        },
        calle: { required: false },
        next: { required: false },
        localidad_id: { required: false },
        municipio_id: { required: false },
      },
    };
  },
  avance(progreso) {
    this.avanceGuardando = Math.round((progreso.loaded * 100) / progreso.total);
  },
  methods: {
    guardar: function () {
      this.$v.organigrama.$touch();
      if (!this.$v.organigrama.$invalid) {
        this.guardando = true;
        api
          .guardarOrganigrama(this.organigrama, this.avance)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.organigrama.id = datos.id;
              this.organigrama.ruta = datos.ruta;
              if (!("children" in this.padre)) {
                this.padre.children = [];
              }
              this.padre.children.push(this.organigrama);
              this.organigrama = {};
              this.actualiza();
              //alert(datos.mensaje);
              this.$refs.organigrama.hide();
            } else {
              let msg = "";
              if (datos != null) {
                msg = datos.mensaje;
              }
              alert("Error al guardar la información: " + msg);
            }
            this.guardando = false;
          })
          .catch((error) => {
            alert("Error al guardar la información: " + error);
            this.guardando = false;
          });
      } else {
        alert("Faltan datos por registrar o existen datos incorrectos");
      }
    },
    guardarActor: function () {
      this.$v.actor.$touch();
      if (!this.$v.actor.$invalid) {
        api
          .guardarActor(this.actor)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.actor.id = datos.actor.id;
              if (this.padre != null) {
                if (!("actores" in this.padre)) {
                  this.padre.actores = [];
                }
                this.padre.actores.push(this.actor);
              }
              this.actor = {};
              this.actualiza();
              //alert(datos.mensaje);
              this.$refs.actor.hide();
            } else {
              let msg = "";
              if (datos != null) {
                msg = datos.mensaje;
              }
              alert("Error al guardar la información: " + msg);
            }
            this.guardando = false;
          })
          .catch((error) => {
            alert("Error al guardar la información: " + error);
            this.guardando = false;
          });
      } else {
        alert("Faltan datos por registrar o existen datos incorrectos");
      }
    },
    addActor: function (item) {
      this.padre = item;
      this.actor = {
        organigrama_id: item.id,
      };
      this.$refs.actor.show();
    },
    editActor: function (actor, item) {
      this.padre = item;
      this.actor = Object.assign({}, actor);
      if (actor.municipio_id != null) {
        this.actualizaLocalidades(actor.municipio_id);
      } else {
        this.localidades = [];
      }
      this.$refs.actor.show();
    },
    delActor: function (actor) {
      if (confirm("¿Confirma eliminar a " + actor.nombre)) {
        api.eliminarActor(actor.id).then((response) => {
          alert(response.data.mensaje);
          if (response.data.respuesta) {
            this.actualiza();
          }
        });
      }
    },
    addNodo: function (item) {
      this.padre = item;
      this.organigrama = {
        padre_id: item.id,
        ruta_padre: item.ruta,
      };
      this.enlaces = [];
      this.$refs.organigrama.show();
    },
    editFiles: function (item) {
      window.open(`organigrama/${item.id}`);
    },
    editNodo: function (item) {
      this.organigrama = Object.assign({}, item);
      this.$refs.organigrama.show();
    },
    expNodo: function (item) {
      this.$router.push({ path: `/organigrama/${item.id}` });
    },
    regresar: function () {
      this.$router.push({ path: "/organigrama" });
    },
    delNodo: function (item) {
      if (confirm("¿Confirma eliminar a " + item.nombre)) {
        api.eliminarOrganigrama(item.id).then((response) => {
          alert(response.data.mensaje);
          if (response.data.respuesta) {
            this.actualiza();
          }
        });
      }
    },

    //Metodos de actualización
    actualiza: function () {
      var id = this.$route.params.id;
      if (typeof id == 'undefined') {
        id = 0;
      } 
      this.id = id;
      api.listarOrganigrama(id).then((response) => {
        if (response.data) {
          this.organigramas = response.data;
        }
      });
    },
    actualizaPartidos() {
      api.listarPartidosPoliticos().then((respuesta) => {
        this.partidos = respuesta.data;
      });
    },
    actualizaMunicipios() {
      api.listarMunicipios().then((respuesta) => {
        this.municipios = respuesta.data;
      });
    },
    actualizaLocalidades() {
      api.listarLocalidades(this.actor.municipio_id).then((respuesta) => {
        this.localidades = respuesta.data;
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
    
  },
  computed: {
    imagen_partido(partido) {
      if (partido.imagen != null) {
        return `background-image:url(${this.ruta}/archivo/${partido.imagen.ruta})`;
      }
      return '';
    }
  },
  mounted() {
    this.actualiza();
    this.actualizaMunicipios();
    this.actualizaPartidos();
    this.$watch(
      () => this.$route.params,
      () => {
        this.actualiza();
      }
    );
  },
  /*watch:{
    $route(){
      this.actualiza();
    }
  },*/ 
};
</script>
<style scoped>
.directorio-container {
  padding: 20px;
  background: rgba(23, 38, 61, 0.8); /* Fondo transparente */
  color: #fff; /* Texto en blanco */
  border-radius: 10px;
  max-width: 70%;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  color: #fff; /* Color de título en blanco */
  font-weight: bold;
  margin-bottom: 20px;
}

.tree-list {
  padding: 0;
  margin-bottom: 20px;
}

.tree-item {
  margin: 5px 0;
}

.custom-modal .form-group {
  margin-bottom: 15px;
}

.custom-modal .form-control {
  background-color: rgba(255, 255, 255, 0.2); /* Fondo transparente en inputs */
  color: #fff; /* Texto blanco */
  border: 1px solid rgba(255, 255, 255, 0.5); /* Borde blanco sutil */
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.custom-modal .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Placeholder blanco tenue */
}

.custom-modal .btn-success {
  background-color: #28a745;
  border: none;
  color: #fff;
  margin-right: 10px;
}

.custom-modal .btn-outline-secondary {
  color: #fff;
  border: 1px solid #fff;
}

.text-danger {
  font-size: 12px;
  color: #ffcccc;
}

.image-uploader,
.pdf-uploader {
  max-width: 100%;
  height: auto;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  color: #fff; /* Etiquetas en blanco */
}

</style>