<template>
  <div>
    <h4>Funciones del sistema</h4>
    <ul>
      <tree-item
        class="item"
        :item="organigramas"
        @add-nodo="addNodo"
        @edit-files="editFiles"
        @edit-nodo="editNodo"
        @del-nodo="delNodo"
        @exp-nodo="expNodo"
      ></tree-item>
    </ul>
 
    <b-modal
      ref="organigrama"
      v-bind:title="organigrama.id ? 'Editar función' : 'Agregar función'"
      centered
      hide-backdrop
    >
      <div class="row">
        <div class="form-group row col-md-12">
        <label class="col-md-5 col-form-label">Tipo</label>
        <div class="col-md-7">
          <select
            v-model="organigrama.tipo"
            :class="estatus($v.organigrama.tipo)"
            class="form-control"
          >
            <option
              value="pagina"
            >
              Página
            </option>
            <option
              value="opcion"
            >
              Opción
            </option>
          </select>
          <div class="errores" v-if="!$v.organigrama.tipo.required">
            Requerido
          </div>
        </div>
      </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Nombre: </label>
          <div class="col-md-7">
            <input
              class="form-control"
              type="text"
              v-model="organigrama.opcion"
              :class="estatus($v.organigrama.opcion)"
            />
            <div class="errores" v-if="!$v.organigrama.opcion.required">
              Requerido
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Clave: </label>
          <div class="col-md-7">
            <input
              class="form-control"
              type="text"
              v-model="organigrama.clave"
              :class="estatus($v.organigrama.clave)"
            />
            <div class="errores" v-if="!$v.organigrama.clave.required">
              Requerido
            </div>
          </div>
        </div>
      </div>
      <br />
      <template #modal-footer="{ hide }">
        <button v-on:click="guardar" class="btn btn-primary">Guardar</button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    
  </div>
</template>    
<script>
import TreeItem from "@/components/TreeItem2.vue";
import api from "@/api";
import { required } from "vuelidate/lib/validators";

// define the tree-item component
export default {
  components: { TreeItem },
  data() {
    return {
      stipo: 1,
      id: null,
      organigrama: {},
      padre: {},
      organigramas: {},
      municipios: [],
      localidades: [],
      guardando: false,
    };
  },
  validations() {
    return {
      organigrama: {
        opcion: {
          required,
        },
        clave: {
          required,
        },
        tipo: {
          required,
        },
      },
    };
  },
  methods: {
    guardar: function () {
      this.$v.organigrama.$touch();
      if (!this.$v.organigrama.$invalid) {
        api
          .guardarFuncion(this.organigrama)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.organigrama.id = datos.id;
              this.organigrama.ruta = datos.ruta;
              if (!("children" in this.padre)) {
                this.padre.children = [];
              }
              this.padre.children.push(this.organigrama);
              this.organigrama = {};
              this.actualiza();
              //alert(datos.mensaje);
              this.$refs.organigrama.hide();
            } else {
              let msg = "";
              if (datos != null) {
                msg = datos.mensaje;
              }
              alert("Error al guardar la información: " + msg);
            }
            this.guardando = false;
          })
          .catch((error) => {
            alert("Error al guardar la información: " + error);
            this.guardando = false;
          });
      } else {
        alert("Faltan datos por registrar o existen datos incorrectos");
      }
    },
    
    addNodo: function (item) {
      this.padre = item;
      this.organigrama = {
        padre_id: item.id,
        ruta_padre: item.ruta,
      };
      this.enlaces = [];
      this.$refs.organigrama.show();
    },
    editFiles: function (item) {
      window.open(`organigrama/${item.id}`);
    },
    editNodo: function (item) {
      this.organigrama = Object.assign({}, item);
      this.$refs.organigrama.show();
    },
    expNodo: function (item) {
      this.$router.push({ path: `/organigrama/${item.id}` });
    },
    regresar: function () {
      this.$router.push({ path: "/organigrama" });
    },
    delNodo: function (item) {
      if (confirm("¿Confirma eliminar a " + item.opcion)) {
        api.eliminarOrganigrama(item.id).then((response) => {
          alert(response.data.mensaje);
          if (response.data.respuesta) {
            this.actualiza();
          }
        });
      }
    },

    //Metodos de actualización
    actualiza: function () {
      let querys = "";
      var id = this.$route.params.id;
      if (id > 0) {
        this.id = id;
      } else {
        this.id = null;
      }
      if (this.id != null) {
        querys = "?id=" + this.id;
      }
      api.listarFunciones(querys).then((response) => {
        if (response.data) {
          this.organigramas = response.data;
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted: function () {
    this.actualiza();
    this.$watch(
      () => this.$route.params,
      () => {
        this.actualiza();
      }
    );
  },
};
</script>
<style>
li {
  list-style: none;
  margin-left: 0px;
}

ul {
  padding-inline-start: 20px;
}
</style>
