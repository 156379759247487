<template>
    <div>
      <h1>Carga de Incidencias</h1>
      <p>Aquí se puede agregar un formulario para cargar incidencias.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "CargaIncidencias"
  };
  </script>
  