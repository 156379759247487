<template>
  <div>
    <h4>Recepción de audiencias</h4>
    <div class="grupo">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Mes:</label>
          <input class="form-control" type="month" v-model="busqueda.mes" />
        </div>
      </div>
      <div class="text-right">
        <b-button @click="actualizaAudiencias">Buscar</b-button>
      </div>
    </div>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>No</th>
          <th>Fecha y hora</th>
          <th>Asunto</th>
          <th>Estatus</th>
          <th>Solicita</th>
          <th>Acciones</th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td>{{ elemento.id }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              {{ elemento.fecha }}</a
            >
          </td>
          <td>{{ elemento.asunto }}</td>
          <td>{{ elemento.estatus_audiencia.estatus_audiencia }}</td>
          <td>
            {{
              elemento.personas.length > 0
                ? elemento.personas[0].nombre +
                  " " +
                  elemento.personas[0].paterno +
                  " " +
                  elemento.personas[0].materno
                : ""
            }}
          </td>
          <td>
            <a
              v-if="elemento.estatus_audiencia.id > 0"
              href="javascript:;"
              @click="editar(elemento)"
            >
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a
              v-if="elemento.estatus_audiencia.id > 0"
              href="javascript:;"
              @click="eliminar(elemento)"
            >
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal size="lg" ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos de la audiencia</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Fecha y hora</label>
          <b-input
            v-model.trim="actual.fecha"
            type="datetime-local"
            :class="estatus($v.actual.fecha)"
          />
          <div class="errores" v-if="!$v.actual.fecha.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Asunto</label>
          <b-textarea
            rows="4"
            v-model.trim="actual.asunto"
            class="form-control"
            :class="estatus($v.actual.asunto)"
          />
          <div class="errores" v-if="!$v.actual.asunto.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label
            >Comisión:

            <a href="javascript:;" @click="agregarPersona()"
              ><font-awesome-icon size="lg" icon="plus-square" /> Agregar</a
            ></label
          >
          <div class="col form-control" style="height: 120px; overflow: auto">
            <div v-for="(p, i) in personas" :key="p.id" style="display: flex">
              <div style="white-space: nowrap">
                <a href="javascript:;" @click="activar(i, p)"
                  ><font-awesome-icon size="lg" icon="check-square" /></a
                >&nbsp;
                <a href="javascript:;" @click="editarPersona(i, p)"
                  ><font-awesome-icon size="lg" icon="edit"
                /></a>
                <a href="javascript:;" @click="eliminarPersona(i, p)"
                  ><font-awesome-icon size="lg" icon="trash" /></a
                >&nbsp;
              </div>
              <div>
                {{ p.puesto }} {{ p.nombre }} {{ p.paterno }} {{ p.materno }}
                {{ p.tel }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-md-12">
          <label for="descripcion">Imagen</label>
          <div style="overflow-y: auto">
            <archivo-imagen
              :foto="true"
              ref="pdf"
              v-model="actual.foto"
            ></archivo-imagen>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal ref="persona" title="Información de la persona">
      <p class="text-justify"></p>
      <div class="form-row grupo">
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Nombre: </label>
          <autocomplete
            v-if="false"
            class-name="form-control"
            input-id="hiddenInputId"
            ref="frmCompanyInput"
            :current-key="buscar.key"
            :current-value="buscar.value"
            search-parm-name="name"
            items-id="id"
            items-value="value"
            url="\getList"
          />
          <div class="col-md-7">
            <input
              class="form-control"
              type="text"
              v-model="persona.nombre"
              :class="estatus($v.persona.nombre)"
            />
            <div class="errores" v-if="!$v.persona.nombre.required">
              Requerido
            </div>
          </div>
        </div>

        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Apellido paterno</label>
          <div class="col-md-7">
            <input
              maxlength="100"
              style="max-width: 500px"
              v-model.trim="persona.paterno"
              class="form-control"
              :class="estatus($v.persona.paterno)"
            />
            <div class="errores" v-if="!$v.persona.paterno.required">
              Requerido
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Apellido materno</label>
          <div class="col-md-7">
            <input
              maxlength="100"
              style="max-width: 500px"
              v-model.trim="persona.materno"
              class="form-control"
              :class="estatus($v.persona.materno)"
            />
            <div class="errores" v-if="!$v.persona.materno.required">
              Requerido
            </div>
          </div>
        </div>
        <hr />

        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Puesto</label>
          <div class="col-md-7">
            <input
              maxlength="100"
              style="max-width: 500px"
              v-model.trim="persona.puesto"
              class="form-control"
              :class="estatus($v.persona.puesto)"
            />
            <div class="errores" v-if="!$v.persona.puesto.required">
              Requerido
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Celular</label>
          <div class="col-md-7">
            <input
              maxlength="100"
              style="max-width: 500px"
              v-model.trim="persona.tel"
              class="form-control"
              :class="estatus($v.persona.tel)"
            />
            <div class="errores" v-if="!$v.persona.tel.required">Requerido</div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Correo electrónico</label>
          <div class="col-md-7">
            <input
              maxlength="100"
              style="max-width: 500px"
              v-model.trim="persona.email"
              class="form-control"
              :class="estatus($v.persona.email)"
            />
            <div class="errores" v-if="!$v.persona.email.required">
              Requerido
            </div>
            <div class="errores" v-if="!$v.persona.email.email">Incorrecto</div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Municipio</label>
          <div class="col-md-7">
            <select
              v-model="persona.municipio_id"
              v-on:change="actualizaLocalidades"
              :class="estatus($v.persona.municipio_id)"
              class="form-control"
            >
              <option
                v-for="municipio in municipios"
                :key="municipio.id"
                v-bind:value="municipio.id"
              >
                {{ municipio.municipio }}
              </option>
            </select>
            <div class="errores" v-if="!$v.persona.municipio_id.required">
              Requerido
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Localidad</label>
          <div class="col-md-7">
            <select
              v-model="persona.localidad_id"
              :class="estatus($v.persona.localidad_id)"
              class="form-control"
            >
              <option
                v-for="localidad in localidades"
                :key="localidad.id"
                v-bind:value="localidad.id"
              >
                {{ localidad.localidad }}
              </option>
            </select>
            <div class="errores" v-if="!$v.persona.localidad_id.required">
              Requerido
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Imagen</label>
          <div class="col-md-7" style="max-width: 300px; overflow-y: auto">
            <archivo-imagen
              :foto="true"
              ref="pdf"
              v-model="persona.foto"
            ></archivo-imagen>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button
          variant="primary"
          class="float-right"
          @click="guardarPersona()"
        >
          {{ indicePersona != null ? "Guardar" : "Agregar" }}
        </b-button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="guardando"
      title="Guardando"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";
import { required, email } from "vuelidate/lib/validators";
import Autocomplete from "../components/Autocomplete.vue";
import ArchivoImagen from "@/components/ArchivoImagen";
import BarraProgreso from "@/components/BarraProgreso";

export default {
  name: "Audienciasr",
  components: { Autocomplete, ArchivoImagen, BarraProgreso },
  data() {
    return {
      titulo: "Agregar audiencia",
      busqueda: { mes: moment(Date.now()).format("YYYY-MM") },
      listado: [],
      persona: {},
      personas: [],
      indicePersona: 0,
      localidades: [],
      municipios: [],
      buscar: {},
      guardando: false,
      avanceGuardando: 0,

      posibles: [],
      actual: {
        nombre: null,
        descripcion: null,
        activo: true,
      },
    };
  },
  validations() {
    return {
      actual: {
        fecha: {
          required,
        },
        asunto: {
          required,
        },
      },
      persona: {
        puesto: {
          required,
        },
        tel: { required },
        email: { required: false, email },
        nombre: {
          required,
        },
        paterno: {
          required,
        },
        materno: {
          required,
        },
        calle: {
          required: false,
        },
        next: {
          required: false,
        },
        localidad_id: {
          required: false,
        },
        municipio_id: {
          required: false,
        },
      },
    };
  },
  methods: {
    agregarPersona() {
      this.indicePersona = null;
      this.persona = { id: 0 };
      this.$refs.persona.show();
    },
    editarPersona(indice, persona) {
      this.indicePersona = indice;
      this.persona = Object.assign({}, persona);
      this.$refs.persona.show();
    },
    eliminarPersona(indice, persona) {
      this.personas.splice(indice, 1);
      if (persona.id > 0) {
        this.personasEliminar.push(persona.id);
      }
    },
    guardarPersona() {
      this.$v.persona.$touch();
      if (!this.$v.persona.$invalid) {
        this.persona.modificado = true;
        if (this.indicePersona == null) {
          this.personas.push(this.persona);
          delete this.persona.accion;
        } else {
          Object.assign(this.personas[this.indicePersona], this.persona);
        }

        this.$refs.persona.hide();
      } else {
        this.$parent.error("Información incompleta");
      }
    },

    agregar() {
      this.titulo = "Agregar audiencia";
      this.personas = [];
      this.personasEliminar = [];
      this.posibles = [];
      let ahora = Date.now();
      this.actual = {
        fecha: moment(ahora).format("YYYY-MM-DDTHH:mm:ss"),
        asunto: null,
      };
      this.$refs.detalle.show();
    },
    editar(elemento) {
      this.titulo = "Editar audiencia";
      this.personas = elemento.personas || [];
      this.personasEliminar = [];
      this.posibles = [];

      let ahora = Date.parse(elemento.fecha);
      this.actual = Object.assign({}, elemento);
      (this.actual.fecha = moment(ahora).format("YYYY-MM-DDTHH:mm:ss")),
        this.$refs.detalle.show();
    },
    eliminar(elemento) {
      if (confirm("Confirma eliminar la audiencia: " + elemento.id)) {
        api
          .eliminarAudiencia(elemento.id)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaAudiencias();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {
            this.$parent.aviso("No se pudo eliminar: " + error);
          });
      }
    },
    avance(progreso) {
      this.avanceGuardando = Math.round((progreso.loaded * 100) / progreso.total);
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        this.guardando = true;
        var enviar = Object.assign({}, this.actual);
        enviar.personas = this.personas;
        enviar.personasmenos = this.personasEliminar;
        api
          .guardarAudiencia(enviar, this.avance)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaAudiencias();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizaMunicipios() {
      api.listarMunicipios().then((respuesta) => {
        this.municipios = respuesta.data;
      });
    },
    actualizaLocalidades() {
      api.listarLocalidades(this.persona.municipio_id).then((respuesta) => {
        this.localidades = respuesta.data;
      });
    },
    actualizaAudiencias() {
      api.listarAudiencias(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaAudiencias();
    this.actualizaMunicipios();
  },
};
</script>

