import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Audienciasr from '../views/Audienciasr.vue'
import Correspondenciar from '../views/Correspondenciar.vue'
import Audiencias from '../views/Audiencias.vue'
import Correspondencia from '../views/Correspondencia.vue'
import MisReportes from '../views/MisReportes.vue'
import Tarjetas from '../views/Tarjetas.vue'
import TarjetasDigitales from '../views/TarjetasDigitales.vue'
import Fichas from '../views/Fichas.vue'
import Reportes from '../views/Reportes.vue'
import Reporte from '../views/Reporte.vue'
import ReportesGraficas from '../views/ReportesGraficas.vue'
import Acceso from '../views/Acceso.vue'
import Entradas from '../views/Entradas.vue'
import EntradasDigitales from '../views/EntradasDigitales.vue'
import Roles from '../views/Roles.vue'
import Funciones from '../views/Funciones.vue'
import TipoEvento from '../views/TipoEvento.vue'
import EstatusAudiencia from '../views/EstatusAudiencia.vue'
import TipoInformacion from '../views/TipoInformacion.vue'
import TipoConflicto from '../views/TipoConflicto.vue'
import TipoTarjeta from '../views/TipoTarjeta.vue'
import TipoActor from '../views/TipoActor.vue'
import PartidoPolitico from '../views/PartidoPolitico.vue'
import Bitacora from '../views/Bitacora.vue'
import Errores from '../views/Errores.vue'
import Usuarios from '../views/Usuarios.vue'
import Actores from '../views/Actores.vue'
import Carpeta from '../views/Carpeta.vue'
import Directorio from '../views/Directorio.vue'
import Salir from '../views/Salir.vue'
import CargaIncidencias from '../views/CargaIncidencias.vue';
import VisualizacionIncidencias from '../views/VisualizacionIncidencias.vue';
import Home from '../views/Home.vue';
import BaseDatos from '../views/BaseDatos.vue';
import SistemaReportes from '../views/SistemaReporte.vue';
import MapasRiesgo from '../views/MapasRiesgo.vue';
import SistemaEscucha from '../views/SistemaEscucha.vue';
import Perfil from '../views/Perfil.vue';
import PanelControl from '../views/PanelControl.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Acceso',
    component: Acceso
  },
  {
    path: '/acceso',
    name: 'Acceso',
    component: Acceso
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
   path:'/panel-control',
   name:'PanelControl',
   component: PanelControl,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/base-datos',
    name: 'BaseDatos',
    component: BaseDatos,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/sistema-reportes',
    name: 'SistemaReportes',
    component: SistemaReportes,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/mapas-riesgo',
    name: 'MapasRiesgo',
    component: MapasRiesgo,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/sistema-escucha',
    name: 'SistemaEscucha',
    component: SistemaEscucha,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next();
      } else {
        next('/acceso');
      }
    }
  },

  
  {
    path: '/audienciasr',
    name: 'Audienciasr',
    component: Audienciasr,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/correspondenciar',
    name: 'Correspondenciar',
    component: Correspondenciar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/audiencias',
    name: 'Audiencias',
    component: Audiencias,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/correspondencia',
    name: 'Correspondencia',
    component: Correspondencia,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/misreportes',
    name: 'MisReportes',
    component: MisReportes,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/reportes-graficas',
    name: 'ReportesGraficas',
    component: ReportesGraficas,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tarjetas',
    name: 'Tarjetas',
    component: Tarjetas,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tarjetas-digitales',
    name: 'TarjetasDigitales',
    component: TarjetasDigitales,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/fichas',
    name: 'Fichas',
    component: Fichas,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/reportes',
    name: 'Reportes',
    component: Reportes,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/entradas/',
    name: 'Entradas',
    component: Entradas,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/entradas-digitales/',
    name: 'EntradasDigitales',
    component: EntradasDigitales,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada()) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/reporte/:id',
    name: 'Reporte',
    component: Reporte,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },

  {
    path: '/salir',
    name: 'Salir',
    component: Salir,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/bitacora',
    name: 'Bitacora',
    component: Bitacora,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },  
  {
    path: '/errores',
    name: 'Errores',
    component: Errores,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },  
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: Usuarios,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/actores',
    name: 'Actores',
    component: Actores,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/carpeta/:id?',
    name: 'Carpeta',
    component: Carpeta,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/directorio/:id?',
    name: 'Directorio',
    component: Directorio,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/funciones',
    name: 'Funciones',
    component: Funciones,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tipoevento',
    name: 'TipoEvento',
    component: TipoEvento,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tipoconflicto',
    name: 'TipoConflicto',
    component: TipoConflicto,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tipoinformacion',
    name: 'TipoInformacion',
    component: TipoInformacion,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/estatusaudiencia',
    name: 'EstatusAudiencia',
    component: EstatusAudiencia,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tipotarjeta',
    name: 'TipoTarjeta',
    component: TipoTarjeta,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tipoactor',
    name: 'TipoActor',
    component: TipoActor,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/partidopolitico',
    name: 'ParticoPolitico',
    component: PartidoPolitico,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/carga-incidencias',
    name: 'CargaIncidencias',
    component: CargaIncidencias
  },
  {
    path: '/visualizacion-incidencias',
    name: 'VisualizacionIncidencias',
    component: VisualizacionIncidencias
  }
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

