<template>
  <div>
    <h4>Listado de fichas</h4>
    <div class="grupo">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Mes:</label>
          <input class="form-control" type="month" v-model="busqueda.mes" />
        </div>
      </div>
      <div class="text-right">
        <b-button @click="actualizaFichas">Buscar</b-button>
      </div>
    </div>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>No</th>
          <th>Fecha y hora</th>
          <th>Directorio</th>
          <th>Descripción</th>
          <th>Ficha</th>
          <th></th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id">
          <td>{{ elemento.id }}</td>
          <td>
              {{ elemento.creado }}
          </td>
          <td>
              {{ elemento.organigrama.nombre }}
          </td>
          <td>{{ elemento.nombre  }}</td>
          <td>
            <a :href="`${ruta}/archivo/${elemento.multimedio.ruta}`" target="_blank">
              <font-awesome-icon size="lg" icon="file-pdf" /> Ver ficha
            </a>
          </td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos de la ficha</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Fecha y hora</label>
          <input class="form-control" disabled="disabled" v-model="actual.creado" />       
        </div>
        <div class="form-group col-md-12">
          <label>Directorio:</label>
          <select class="form-control" v-model="actual.organigrama_id">
            <option  selected>Seleccione</option>
            <option
              v-for="tt in organigrama"
              :key="tt.id"
              v-bind:value="tt.id"
            >
              {{ '&nbsp;'.repeat(tt.nivel*2)  }} {{ tt.nombre }} 
            </option>
          </select>
          <div class="errores" v-if="!$v.actual.organigrama_id.required">Requerido</div>
        </div>
        <div  class="form-group col-md-12">
          <label>Nombre</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.nombre"
            class="form-control"
          />
          <div class="errores" v-if="!$v.actual.nombre.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label for="descripcion">Ficha</label>
          <div style="max-width: 400px; overflow-y: auto">
            <archivo-pdf ref="multimedio" v-model="actual.multimedio"></archivo-pdf>
          </div>
          <div class="errores" v-if="!$v.actual.multimedio.required">Requerido</div>
        </div>
        
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal v-model="guardando" title="Guardando" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>    
    
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import moment from 'moment';
import { required } from "vuelidate/lib/validators";
import ArchivoPdf from "@/components/ArchivoPdf";
import BarraProgreso from "@/components/BarraProgreso";

export default {
  name: "Fichas",
  components: { ArchivoPdf, BarraProgreso  },
  data() {
    return {
      ruta: API_URL,
      busqueda: { mes: moment(Date.now()).format("YYYY-MM") },
      titulo: "Agregar ficha",
      listado: [],
      
      buscar: {},

      usuario: { id: 0 },
      actual: {
        organigrama_id: null,
        nombre: null,
      },
      organigrama: [],
      guardando: false,
      avanceGuardando: 0,
    };
  },
  validations() {
    return {
      
      actual: {
        multimedio: {
          required,
        },
        nombre: {
          required,
        },
        organigrama_id: {
          required,
        },
      },      
    };
  },
  methods: {
    seleccionarUsuario() {
      this.usuario = { id: 0 };
      this.$refs.usuario.show();
    },

    agregar() {
      this.titulo = "Agregar ficha"; 
      let ahora = Date.now();
      this.actual = {
        creado: moment(ahora).format('YYYY-MM-DD HH:mm:ss'),
        organigrama_id: null,
        nombre: null,
      };
      this.$refs.detalle.show();
    },
    editar(elemento) {
      this.titulo = "Editar ficha informativa";
      this.actual = Object.assign({}, elemento);
      this.$refs.detalle.show();
    },
    eliminar(elemento) {
      if (confirm("Confirma eliminar la ficha informativa: " + elemento.id)) {
        api
          .eliminarFicha(elemento.id)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaFichas();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {
            this.$parent.aviso("No se pudo eliminar: " + error);
          });
      }
    },
    avance(progreso) {
      this.avanceGuardando = Math.round((progreso.loaded * 100) / progreso.total);
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        this.guardando = true;
        if (this.actual.pdf instanceof Object) {
          delete this.actual.pdf
        }
        api
          .guardarFicha(this.actual, this.avance)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaFichas();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    actualizaFichas() {
      api.listarFichas(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaFichas();
    api.listarOrganigramaSelect().then((respuesta) => {
      this.organigrama = respuesta.data;
    });
  },
};
</script>

