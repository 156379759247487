<template>
  <div>
    <h4>Gráficas de avance por grupo y usuario</h4>
    
    <div class="form-row grupo">
      <div class="form-group col-md-4">
          <label>Semana:</label>
          <input type="week" class="form-control"
            v-model="busqueda.semana" v-on:change="actualizaDatos"
          />
        </div>
      <div class="form-group col-md-4">
        <label>Elija el grupo</label>
        <select
          class="form-control"
          v-model="busqueda.id_grupo" v-on:change="busqueda.id_usuario=0; actualizaDatos(); actualizaUsuarios();"
        >
          <option value="0" selected>Todos</option>
          <option
            v-for="grupo in grupos"
            :key="grupo.id"
            v-bind:value="grupo.id"
          >
            {{ grupo.grupo }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label>Elija el usuario</label>
        <select
          class="form-control"          
          v-model="busqueda.id_usuario" v-on:change="actualizaDatos"
        >
          <option value="0" selected>Todos</option>
          <option
            v-for="usuario in usuarios"
            :key="usuario.id"
            v-bind:value="usuario.id"
          >
            {{ usuario.paterno }} {{ usuario.materno }} {{ usuario.nombre }}
          </option>
        </select>
      </div>
      
      <div class="form-group col-md-12 text-right">
        <button class="btn btn-primary" v-on:click.stop.prevent="actualizaDatos">
          Buscar
        </button>
      </div>
    </div>

    <div>
      <bar-chart :chart-data="datos" :width="8" :height="3" />
    </div>
  </div>
</template>

<script>
import api from "@/api";
import BarChart from "../components/BarChart";
import moment from "moment";

export default {
  name: "ReportesGraficas",
  components: { BarChart },
  data() {
    return {
      grupos: [{id: 2, grupo:"Coordinador regional"}, {id:1, grupo: "Investigador"}, {id:36, grupo: "Área Técnica"}],
      usuarios: [],
      datos: {},
      busqueda: {
        id_grupo: 0,
        id_usuario: 0,
        semana: null,
      },
    };
  },
  methods: {

    actualizaDatos() {
      api
        .listarGraficaAvance(this.busqueda)
        .then((respuesta) => {
          let r = respuesta.data;
          if (r.respuesta) {
            this.datos = r.datos;
          } else {
            this.datos = {};
          }
        });
    },
    actualizaUsuarios() {
      let roles = [];
      if (this.busqueda.id_grupo == 0) {
        roles.push(2);
        roles.push(1);
        roles.push(36);
      } else {
        roles.push(this.busqueda.id_grupo);
      }
      api.listarUsuarioRol(roles).then((r) => {
          if (r.data) {
            this.usuarios = r.data;
          } else {
            this.usuarios = [];
          }
      });
    }
  },
  mounted() {
    let m = moment();
    this.busqueda.semana = m.year()+"-W"+(m.week()-1);
    this.actualizaDatos();
    this.actualizaUsuarios();
  },
};
</script>

