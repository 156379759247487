<template>
  <div>
     <!-- Logo independiente en la esquina superior derecha -->
     <div class="header-logo">
       <router-link to="/home">
         <img src="@/assets/logo_gob.png" alt="Logo Gobierno" class="logo-superior-derecha" />
       </router-link>
     </div>
   <div class="container-fluid" :class="{ 'collapsed': isSidebarCollapsed }">
     <div class="row no-gutters">
       <!-- Sidebar -->
       <aside class="sidebar text-white">
         <div class="profile text-center my-4">
           <img src="@/assets/perfil.jpg" class="rounded-circle img-fluid" alt="User Photo" />
           <h4 class="mt-2" v-if="!isSidebarCollapsed">{{ usuarioNombre }}</h4>
           <p v-if="!isSidebarCollapsed">{{ usuarioRol }}</p>
         </div>
         <nav class="nav flex-column mb-auto">
         <router-link to="/home"  class="nav-link text-white d-flex align-items-center">
           <img src="@/assets/icons/home.png" class="icon hover-pointer" />
           <span v-if="!isSidebarCollapsed">Inicio</span>
         </router-link>
         <li v-for="(seccion, index) in secciones" :key="index" class="nav-item">
           <a 
             href="#" 
             class="nav-link text-white d-flex align-items-center hover-pointer" 
             :class="{ active: seccionActiva === seccion.nombre }" 
             @click.prevent="cambiarVista(seccion.nombre)"
           >
             <img :src="seccion.icono" class="icon hover-pointer" />
             <span v-if="!isSidebarCollapsed">{{ seccion.titulo }}</span>
           </a>
         </li>
         </nav>
 
         <!-- Línea divisoria -->
         <hr class="sidebar-divider mt-4 mb-4" />
 
         <!-- Footer del menú -->
         <div class="footer-menu">
           <router-link to="/perfil" class="nav-link text-white d-flex align-items-center mb-2" :class="{ active: vistaActual === 'home' }">
             <img src="@/assets/icons/user.png" class="icon hover-pointer" /> <span v-if="!isSidebarCollapsed">Mi perfil</span>
           </router-link>
           <router-link to="/acceso" class="nav-link text-white d-flex align-items-center">
             <img src="@/assets/icons/power.png" class="icon hover-pointer" /> <span v-if="!isSidebarCollapsed">Cerrar Sesión</span>
           </router-link>
         </div>
 
         <!-- Sidebar Toggle Button -->
         <div :class="['toggle-container', isSidebarCollapsed ? 'collapsed' : 'expanded']">
           <button class="btn btn-outline-secondary toggle-button" @click="toggleSidebar">
             <i :class="isSidebarCollapsed ? 'bi bi-chevron-right' : 'bi bi-chevron-left'"></i>
           </button>          
         </div>
       </aside>
 
       <!-- Contenido del componente -->
       <div class="content-wrapper">
         <div class="content">
            <h2>Sistema de Reportes</h2>
            <component :is="componenteActual" />
         </div>
       </div>
     </div>
   </div>
 </div>
 </template>
 
 <script>
 import { mapGetters } from 'vuex'; // Importar mapGetters
 import MisReportes from './MisReportes.vue';
 import ReportesCampo from './Entradas.vue';
 import ReportesDigitales from './EntradasDigitales.vue';
 import GraficaAvance from './ReportesGraficas.vue';
 
 export default {
   name: 'SistemaReporte',
   data() {
     return {
       isSidebarCollapsed: false,
       seccionActiva: 'misReportes', 
       secciones: [
         { nombre: 'misReportes', titulo: 'Reportes', icono: require('@/assets/icons/misrepo.png') },
         { nombre: 'reportesCampo', titulo: 'Reportes de Campo', icono: require('@/assets/icons/reporcampo.png') },
         { nombre: 'reportesDigitales', titulo: 'Reportes Digitales', icono: require('@/assets/icons/repordig.png') },
         { nombre: 'graficaAvance', titulo: 'Gráfica de Avance', icono: require('@/assets/icons/grafrepo.png') },
       ],
       vistaActual: null // Vista inicial
     };
   },
   computed: {
     ...mapGetters(['usuario']),  // Mapea el getter `usuario` desde el store
 
     componenteActual() {
       switch (this.vistaActual) {
         case 'misReportes':
           return require('./MisReportes.vue').default;
         case 'reportesCampo':
           return require('./Entradas.vue').default;
         case 'reportesDigitales':
           return require('./EntradasDigitales.vue').default;
         case 'graficaAvance':
           return require('./ReportesGraficas.vue').default;
         default:
           return null;
       }
     },
 
     usuarioNombre() {
       return this.usuario ? this.usuario.nombre : 'Usuario'; // Muestra el nombre si existe
     },
 
     usuarioRol() {
       return this.usuario ? this.usuario.rol : 'Rol'; // Muestra el rol si existe
     }
   },
   mounted() {
     // Establece la vista inicial en "Mis Reportes" al montar el componente
     this.vistaActual = 'misReportes';
   },
   methods: {
     cambiarVista(vista) {
       this.vistaActual = vista;
       this.seccionActiva = vista; // Marca la sección como activa
     },
     toggleSidebar() {
       this.isSidebarCollapsed = !this.isSidebarCollapsed;
     }
   }
 };
 </script>

<style scoped>
.container-fluid {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  width: 100%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  overflow-y: auto;
}
.header-logo {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 1050; /* Asegura que el logo esté por encima de otros elementos */
}

.logo-superior-derecha {
  width: 150px; /* Ajusta el tamaño del logo según sea necesario */
  height: auto;
}

.sidebar {
  width: 250px;
  height: 100%;
  transition: width 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #17263d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.collapsed .sidebar {
  width: 80px;
}

.content-wrapper {
  flex-grow: 1;
  margin-left: 270px; /* Ajusta el margen para que el contenido se expanda */
  width: calc(100% - 250px); /* Usa el ancho completo menos el espacio del sidebar */
  transition: margin-left 0.3s, width 0.3s;
  padding: 10px;
}

.collapsed .content-wrapper {
  margin-left: 80px; /* Ajusta el margen cuando el sidebar está colapsado */
  width: calc(100% - 80px); /* Usa casi todo el espacio de la pantalla */
}

.content {
  width: 100%; /* Asegura que el contenido interno use todo el ancho disponible */
}
/* Divider style */
.sidebar-divider {
  border-top: 1px solid #ccc;
}

/* Estilo del footer menu */
.footer-menu {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinea los elementos al centro */
}

.footer-menu .nav-link {
  width: 100%; /* Para que los botones ocupen el ancho completo */
  padding: 5px 15px;
  text-align: center; /* Centra el texto de los botones */
}

.footer-menu .nav-link:not(:last-child) {
  margin-bottom: 1px; /* Espacio entre botones */
}

/* Estilo del botón */
.toggle-container {
  display: flex;
  justify-content: center; /* Centra el botón horizontalmente */
  margin-bottom: 20px; /* Espacio adicional debajo del botón */
}

.toggle-button {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%; /* Botón redondeado */
  padding: 5px;
  background-color: transparent; /* Color de fondo visible */
  color: #fff; /* Color de texto visible */
  transition: opacity 0.3s ease; 
}
.toggle-button:hover{
  background-color: rgba(1, 187, 210);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .sidebar {
    width: 150px; /* Ajusta el tamaño del sidebar en pantallas pequeñas */
  }

  .collapsed .sidebar {
    width: 50px;
  }

  .content-wrapper {
    margin-left: 150px;
    width: calc(100% - 150px);
  }

  .collapsed .content-wrapper {
    margin-left: 50px;
    width: calc(100% - 50px);
  }
}

.content {
  width: 200%;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.nav-link:hover {
  transform: translateY(2px); /* Crea el efecto de hundimiento */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Agrega una sombra sutil */
  background-color: rgba(255, 255, 255, 0.1); /* Color de fondo al pasar el cursor */
  border-radius: 5px; /* Bordes redondeados para que el efecto se vea mejor */
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2); /* Color de fondo para la sección activa */
  border-radius: 5px; /* Bordes redondeados */
}

/* Estilo para las imágenes de los íconos */
.icon {
  width: 24px; /* Ajusta el tamaño del ícono según sea necesario */
  height: 24px;
  margin-right: 10px;
  transition: transform 0.2s;
}

.icon:hover {
  cursor: pointer; /* Cambia el cursor a pointer */
  transform: scale(1.3); /* Aplica un efecto de escala al pasar el mouse */
}

.profile img {
  width: 150px;
}
</style>








  